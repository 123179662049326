
export const isValidDate = (
    year: number,
    month: number,
    day: number
): boolean => {
    try {
        const d = new Date(year, month - 1, day);
        return (month - 1 === d.getMonth())
    } catch (error) {
        return false;
    }
};
