import {Control, FieldPath, FieldValues, useController} from "react-hook-form";
import {InputHTMLAttributes, ReactNode} from "react";
import clsx from "clsx";

type Props<T extends FieldValues> = {
    control: Control<T>
    name: FieldPath<T>
    defaultValue?: string|number|undefined
    children: ReactNode

} & InputHTMLAttributes<HTMLSelectElement>

const variants = {
    error: {
        className: "bg-red-100 border-red-500 focus:border-red-500"
    }
}

export const SelectFieldControl = <T extends FieldValues>({control, name, className, autoFocus, placeholder, children} : Props<T>) => {

    const { field, fieldState: {invalid, isDirty}} = useController({
        name: name,
        control: control,
    });

    return (
        <select
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={
                clsx(
                    "w-full",
                    "text-sm",
                    "border-solid border-gray-300",
                    "focus:ring-0 focus:border-primary-500",
                    (invalid) ? variants.error.className : '',
                    (isDirty || field.value !== '') ? "text-gray-900" : "text-gray-400",
                    "caret-primary-500",
                    className
                )
            }
            onChange={field.onChange}
            onBlur={field.onBlur}
            ref={field.ref}
            defaultValue={field.value}
        >
            {children}
        </select>
    )
}