import {useLocation, useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {ReContractFormInputState} from "../../atoms/ReContractFormInputState";
import {useReContractForm} from "../../hooks/useReContractForm";
import {ReContractFormInputs} from "../../types/ReContractFormInputs";
import {TableHeader} from "../Elements/Table/TableHeader";
import {TableCell} from "../Elements/Table/TableCell";
import {InputFieldControl} from "../Forms/InputFieldControl";
import {ValidationErrorText} from "../Forms/ValidationErrorText";
import clsx from "clsx";
import {PrefectureSelectFieldControl} from "../Forms/PrefectureSelectFieldControl";
import {DatePickerFieldControl} from "../Forms/DatePickerFieldControl";
import {RequiredBadge} from "../Elements/RequiredBadge";
import {CheckBoxControl} from "../Forms/CheckBoxControl";
import React from "react";
import {HourSelectFieldControl} from "../Forms/HourSelectFieldControl";
import {MinuteSelectFieldControl} from "../Forms/MinuteSelectFieldControl";
import {Helmet} from "react-helmet-async";

export const ReContractForm = () => {
    const navigate = useNavigate()
    const setReContractFormInputState = useSetRecoilState(ReContractFormInputState)
    const pathname = useLocation().pathname;

    const {
        control,
        handleSubmit,
        errors,
    } = useReContractForm()

    const onHandleSubmit = (data: ReContractFormInputs) => {
        if (pathname === '/re-contract/mobasoku') {
            data.contract = 'mobasoku'
        } else {
            data.contract = 'quick-kaitori'
        }
        setReContractFormInputState(data)
        navigate('/re-contract/confirm')
    }

    return (
        <>
            <Helmet>
                {(pathname !== '/contract/mobasoku' && pathname !== '/mobasoku') && (
                    <title>クイック買取</title>
                )}
                {(pathname === '/contract/mobasoku' || pathname === '/mobasoku') && (
                    <title>クイック買取</title>
                )}
            </Helmet>
            <div className="container prose mx-auto max-w-none">
                <div className="px-4 max-w-5xl mx-auto bg-white border lg:px-24 lg:mt-16">
                    <section>
                        <h2>再契約専用フォーム</h2>
                        <p>迷惑メール設定の場合はinfo@quickkaitori.netからのメールを受信できるように設定変更してからご記入ください。</p>
                        <p>こちらのフォームに入力いただいた内容を元に、ご指定のメールアドレスへ電子契約書メールが送付されます。送信後は受信メールを必ずご確認ください。</p>

                        {Object.keys(errors).length > 0 && (
                            <div className="border-2 border-red-500 bg-red-100 text-red-500 py-2 px-3">入力項目にエラーがあります。<br />入力内容をご確認ください。</div>
                        )}

                    </section>
                    <form onSubmit={handleSubmit(onHandleSubmit)}>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">１．ご本人様（お名前など）について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="会員番号" required />
                                    <TableCell>
                                        <span className="text-sm inline-block">*LINEにてお申込みの際に弊社より発行した会員番号をご記入ください。</span>
                                        <InputFieldControl type="text" control={control} name="userId" />
                                        {errors.userId?.message && (<ValidationErrorText>{errors.userId.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="原契約締結期日及び時刻" required />
                                    <TableCell>
                                        <DatePickerFieldControl control={control} name="contractDate" />
                                        {errors.contractDate?.message && (<ValidationErrorText>{errors.contractDate.message}</ValidationErrorText>)}
                                        <div className="flex mt-1 gap-x-1">
                                            <div>
                                                <HourSelectFieldControl control={control} name={'contractTime.hour'} />
                                                {errors.contractTime?.hour?.message && (<ValidationErrorText>{errors.contractTime.hour.message}</ValidationErrorText>)}
                                            </div>
                                            <div>
                                                <MinuteSelectFieldControl control={control} name={'contractTime.minute'} />
                                                {errors.contractTime?.minute?.message && (<ValidationErrorText>{errors.contractTime.minute.message}</ValidationErrorText>)}
                                            </div>
                                        </div>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="支払期日" required />
                                    <TableCell>
                                        <DatePickerFieldControl control={control} name="paymentDue" />
                                        {errors.paymentDue?.message && (<ValidationErrorText>{errors.paymentDue.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="お名前(全角)" required />
                                    <TableCell>
                                        <div className="flex gap-1">
                                            <div>
                                                <span className="text-xs">姓</span>
                                                <InputFieldControl type="text" control={control} name="lastName" placeholder="佐藤" />
                                                {errors.lastName?.message && (<ValidationErrorText>{errors.lastName.message}</ValidationErrorText>)}
                                            </div>
                                            <div>
                                                <span className="text-xs">名</span>
                                                <InputFieldControl type="text" control={control} name="firstName" placeholder="一郎" />
                                                {errors.firstName?.message && (<ValidationErrorText>{errors.firstName.message}</ValidationErrorText>)}
                                            </div>
                                        </div>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="携帯番号" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="phoneNumber" placeholder="例) 09056781234" />
                                        {errors.phoneNumber?.message && (<ValidationErrorText className="block">{errors.phoneNumber.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>

                                <tr>
                                    <TableHeader label="メールアドレス" required />
                                    <TableCell>
                                        <div className="mb-3">
                                            <span className="text-sm inline-block">*電子契約書を受取る事ができるメールアドレスをご記入ください</span>
                                            <InputFieldControl type="email" control={control} name="email" placeholder="example@example.com" />
                                            {errors.email?.message && (<ValidationErrorText>{errors.email.message}</ValidationErrorText>)}
                                        </div>
                                        <div>
                                            <span className="text-sm inline-block">確認のためもう一度ご入力してください</span>
                                            <InputFieldControl type="email" control={control} name="emailConfirmation" placeholder="example@example.com" />
                                            {errors.emailConfirmation?.message && (<ValidationErrorText>{errors.emailConfirmation.message}</ValidationErrorText>)}
                                        </div>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="ご住所" required />
                                    <TableCell>
                                        <div className="mb-1">
                                            <span className="text-xs">郵便番号</span>
                                            <InputFieldControl type="text" control={control} name="postalCode" placeholder="1234567" />
                                            {errors.postalCode?.message && (<ValidationErrorText>{errors.postalCode.message}</ValidationErrorText>)}
                                        </div>
                                        <div className="mb-1">
                                            <span className="text-xs">都道府県</span>
                                            <PrefectureSelectFieldControl control={control} name="prefecture" />
                                            {errors.prefecture?.message && (<ValidationErrorText>{errors.prefecture.message}</ValidationErrorText>)}
                                        </div>
                                        <div>
                                            <span className="text-xs">ご住所</span>
                                            <InputFieldControl type="text" control={control} name="address" placeholder="○○市○○1-2-3 ○○マンション 101号室" />
                                            {errors.address?.message && (<ValidationErrorText>{errors.address.message}</ValidationErrorText>)}
                                        </div>
                                        <div className="text-sm">
                                            <p>*ご住所は、本人確認書類（運転免許証など）と同じ内容をご入力してください。</p>
                                            <p>*住所の丁目や番地、号等は、－（ハイフン）でご入力してください。</p>
                                        </div>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <div className="px-3">
                                <RequiredBadge />
                                <label className="align-middle inline-block ml-2 mt-1 cursor-pointer">
                                    <CheckBoxControl control={control} name="agreements1" className="mr-2 -mt-1" />
                                    <span>乙は甲への送金によって本契約に同意します。</span>
                                </label>
                            </div>
                            {errors.agreements1?.message && (<ValidationErrorText className="block">{errors.agreements1.message}</ValidationErrorText>)}
                        </section>
                        <section>
                            <div className="px-3">
                                <RequiredBadge />
                                <label className="align-middle inline-block ml-2 mt-1 cursor-pointer">
                                    <CheckBoxControl control={control} name="agreements2" className="mr-2 -mt-1" />
                                    <span>必須甲は原契約書締結時に同意した各種の内容を、本覚書締結においても同意します。</span>
                                </label>
                            </div>
                            {errors.agreements2?.message && (<ValidationErrorText className="block">{errors.agreements2.message}</ValidationErrorText>)}
                        </section>
                        <section>
                            <p className="text-center">
                                <input
                                    className={clsx(
                                        'bg-[#9b8d77] text-white',
                                        'border-b-2 border-b-black',
                                        'rounded-md',
                                        'w-64 h-12',
                                        'cursor-pointer',
                                        'hover:opacity-70',
                                        'disabled:bg-gray-400 disabled:cursor-not-allowed'
                                    )}
                                    type="submit"
                                    value="確認画面へ" />
                            </p>
                        </section>
                    </form>
                </div>
            </div>
        </>
    )

}
