import {Control, Controller, FieldPath, FieldValues} from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import {ja} from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css"
import clsx from "clsx";

type Props<T extends FieldValues> = {
    control: Control<T>
    name: FieldPath<T>
}

const variants = {
    error: {
        className: "bg-red-100 border-red-500 focus:border-red-500"
    }
}

export const DatePickerFieldControl = <T extends FieldValues>({control, name}: Props<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({field: {onChange, value}, fieldState: {invalid}}) => (
                <ReactDatePicker
                    className={
                        clsx(
                            "border border-gray-300 w-full",
                            (invalid) ? variants.error.className : '',
                        )
                    }
                    dateFormat="yyyy/MM/dd"
                    locale={ja}
                    onChange={onChange}
                    selected={value as Date}
                    dayClassName={(date) =>{
                        const dayOfWeek = date.getDay()
                        if (dayOfWeek === 6) return 'day-sat'
                        if (dayOfWeek === 0) return 'day-sun'
                        return null
                    }}
                />
            )}
        />
    )
}