import {Control, FieldPath, FieldValues} from "react-hook-form";
import {InputHTMLAttributes} from "react";
import {SelectFieldControl} from "./SelectFieldControl";
import prefecturesJson from "../../json/prefecture.json";

type Props<T extends FieldValues> = {
    control: Control<T>
    name: FieldPath<T>

} & InputHTMLAttributes<HTMLSelectElement>

export const PrefectureSelectFieldControl = <T extends FieldValues>({control, name, className, autoFocus, placeholder} : Props<T>) => {
    return (
        <SelectFieldControl
            control={control}
            name={name}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={className}
        >
            <option value="">選択してください</option>
            {prefecturesJson.prefectures.map((prefecture, idx) => <option key={idx} value={prefecture.code}>{prefecture.name}</option>)}
        </SelectFieldControl>
    )
}