import React, {ReactNode} from "react";

type Props = {
    children: ReactNode
}
export const TableCell = ({children} : Props) => {
    return (
        <td className="py-2 px-4 block">
            {children}
        </td>
    )
}