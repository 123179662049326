import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {NewContractFormInputs} from "../types/NewContractFormInputs";
import {zodResolver} from "@hookform/resolvers/zod";
import {NewContractFormSchema} from "../schemas/NewContractFormSchema";
import {useEffect, useMemo} from "react";
import {useRecoilValue} from "recoil";
import {NewContractFormInputState} from "../atoms/NewContractFormInputState";


export const useNewContractForm = () => {

    const newContractFormInputState = useRecoilValue(NewContractFormInputState)

    const defaultValues = useMemo(() => newContractFormInputState, [newContractFormInputState])

    const {
        control,
        handleSubmit: handleSubmitOriginal,
        formState: {errors, isValid},
        reset,
    } =useForm<NewContractFormInputs>({
        defaultValues,
        resolver: zodResolver(NewContractFormSchema)
    })

    useEffect(() => {
        reset(newContractFormInputState)
    }, [newContractFormInputState, reset])


    const handleSubmit = (
        onValid: SubmitHandler<NewContractFormInputs>,
        onInvalid?: SubmitErrorHandler<Error>
    ) => handleSubmitOriginal(onValid, onInvalid)

    return {
        control,
        errors,
        handleSubmit,
        isValid
    }
}