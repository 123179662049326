import {z} from "zod";
import {isValidDate} from "../libs/validation/isValidDate";
import {toHankaku} from "../utils/toHankaku";

const schemaErrorMap: z.ZodErrorMap = (issue, ctx) => {
    switch (issue.code) {
        case z.ZodIssueCode.invalid_literal:
            return { message: '同意が必要です' }
    }
    return { message: ctx.defaultError }
};

export const NewContractFormSchema = z.object({
    userId: z.string({required_error: '入力してください'})
        .max(100, '100文字以内で入力してください')
        .nonempty('入力してください'),
    firstName: z.string({required_error: '入力してください'})
        .max(10, '10文字以内で入力してください')
        .nonempty('入力してください'),
    lastName: z.string({required_error: '入力してください'})
        .max(10,'10文字以内で入力してください')
        .nonempty('入力してください'),
    birth: z.object({
        year: z.string({required_error: '生年月日(年):選択してください'}).nonempty('生年月日(年):選択してください'),
        month: z.string({required_error: '生年月日(月):選択してください'}).nonempty('生年月日(月):選択してください'),
        day: z.string({required_error: '生年月日(日):選択してください'}).nonempty('生年月日(日):選択してください')
    }).refine((birth) => {
            return isValidDate(parseInt(birth.year), parseInt(birth.month), parseInt(birth.day))
        }, {message: '正しい生年月日を入力してください'}
    ),
    phoneNumber: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
        .regex(/^0[-\d]{9,12}$/, '正しい形式の電話番号を入力してください')
        .nonempty('入力してください'),
    ),
    email: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .max(255, '255文字以内で入力してください')
            .email('正しい形式のメールアドレスを入力してください')
            .nonempty('入力してください'),
    ),
    emailConfirmation: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .max(255, '255文字以内で入力してください')
            .email('正しい形式のメールアドレスを入力してください')
            .nonempty('入力してください'),
    ),
    postalCode: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .regex(/^[0-9]{3}-?[0-9]{4}$/, '正しい形式の郵便番号を入力してください')
            .nonempty('入力してください'),
    ),
    prefecture: z.string({required_error: '選択してください'})
        .nonempty('選択してください'),
    address: z.string({required_error: '入力してください'})
        .max(500, '500文字以内で入力してください')
        .nonempty('入力してください'),
    emergencyContactName: z.string({required_error: '入力してください'})
        .max(20, '20文字以内で入力してください')
        .nonempty('入力してください'),
    emergencyContactNumber: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .regex(/^0[-\d]{9,12}$/, '正しい形式の電話番号を入力してください')
            .nonempty('入力してください'),
    ),
    emergencyContactRelation: z.string({required_error: '入力してください'})
        .max(5, '5文字以内で入力してください')
        .nonempty('入力してください'),
    clientName: z.string({required_error: '入力してください'})
        .max(255, '255文字以内で入力してください')
        .nonempty('入力してください'),
    clientBranch: z.string({required_error: '入力してください'})
        .max(255, '255文字以内で入力してください')
        .nonempty('入力してください'),
    clientPostalCode: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .regex(/^[0-9]{3}-?[0-9]{4}$/, '正しい形式の郵便番号を入力してください')
            .nonempty('入力してください'),
    ),
    clientPrefecture: z.string({required_error: '選択してください'})
        .nonempty('選択してください'),
    clientAddress: z.string({required_error: '入力してください'})
        .max(500, '500文字以内で入力してください')
        .nonempty('入力してください'),
    clientPhoneNumber: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .regex(/^0[-\d]{9,12}$/, '正しい形式の電話番号を入力してください')
            .nonempty('入力してください'),
    ),
    liquidationDate: z.date(),
    transferAmount: z.string({required_error: '入力してください'})
        .regex(/^([1-9１-９][0-9０-９]*)$/, '数値で入力してください'),
    receiveAmount: z.string({required_error: '入力してください'})
        .regex(/^([1-9１-９][0-9０-９]*)$/, '数値で入力してください'),
    bankName: z.string({required_error: '入力してください'})
        .max(100, '100文字以内で入力してください')
        .nonempty('入力してください'),
    bankBranch: z.string({required_error: '入力してください'})
        .max(100, '100文字以内で入力してください')
        .nonempty('入力してください'),
    bankType: z.string({required_error: '入力してください'})
        .max(2, '2文字以内で入力してください')
        .nonempty('入力してください'),
    bankNumber: z.string({required_error: '入力してください'})
        .max(10, '10文字以内で入力してください')
        .nonempty('入力してください'),
    bankHolderName: z.string({required_error: '入力してください'})
        .max(100, '100文字以内で入力してください')
        .nonempty('入力してください'),
    agreements: z.literal(true, {
        errorMap: schemaErrorMap
    }),
    compliance: z.literal(true, {
        errorMap: schemaErrorMap
    })
}).refine(
    (args) => {
        return args.email === args.emailConfirmation
    }, {
        message: '入力されたメールアドレスが異なります',
        path: ['emailConfirmation']
    }
)