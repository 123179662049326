import {
  Html,
  Head, Body, Container, Section, Row, Column, Heading,Text
} from "@react-email/components";
import {NewContractFormInputsSelector} from "../../types/NewContractFormInputs";
import { format } from 'date-fns'

type Props = NewContractFormInputsSelector

const MobasokuNewContractEmailTemplate = (props: Props) => {
  const now = new Date()
  const createdAt = format(now, 'yyyy-MM-dd  HH時mm分')
  const createdDateJa = format(now, 'yyyy年M月d日')

  return (
      <Html lang="ja">
        <Head />
        <Body style={main}>
          <Container style={container}>
            <Section>
              <Row>
                <Column align="center">
                  <Heading style={h1}>「クイック買取」規約書</Heading>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text>規約書送付⽇時：{createdAt}</Text>
                </Column>
              </Row>
            </Section>
            <Section>
              <Text style={indentText}>
                ピクチェンジ会員規約以下「本規約」といいます。クイック買取以下「当社」といいますの提供する、ピクチェンジ(以下「本サービス」といいますを利用して商品を売渡 し、当社の入会を受ける会員(以下「利用者」といいます)と商品を買取り、入会する加 盟店と、当社との間に適用されるものをいいます。利用者は、予め会員登録し、本規約に同意いただいた上で、実際に本サービスを利用することによって、本規約に有効かつ取消不能な同意をしたものとみなします。 甲と乙の電子取引が円滑に行われるようにオンライン取引サイトにおいて代行サービスを提供します。
              </Text>
            </Section>
            <Section>
              <Text>
                第 1 条(定義)<br />
                本規約における各種用語の定義および規定は、以下のとおりとします。<br />
                1. (ピクチェンジ)とは、会員資格を持つ利用者と加盟店当社を含む)とが、本サービスの利用契約を電子的に締結できるプラットフォームサービスをいい、本商品の売買する以 下の取引をいいます。<br />
                ① 利用者は本商品の画像データを当社に開示して仮査定を受けます。<br />
                ② 利用者は本商品を売渡し、加盟店(当社を含む)から仮査定の手付金を受取ります。<br />
                ③ 利用者は返還期日終了後に、本商品を加盟店(当社を含む)に返還し、買取価格の本査定を受けます。<br />
                ④ 本査定終了後に、利用者は本査定価格と手付金の差額を受取ります。<br />
                ⑤ 利用者は加盟店が査定する本査定価格に不満がある場合は、手付金を返戻して本商品を受取ります。<br />
                ⑥ 本査定価格が手付金より少ない場合、利用者は手付金から差額を返戻します。<br />
                2. (譲渡できる商品)とは、利用者が本サービスを利用して加盟店(当社を含む)に売渡す商品(以下「本商品」といいます)をいい、利用者が本サービスに任意で登録する、本商品に対する真贋性、所有性、所有の正当性、現在価値等の内容に、以下の虚偽が存在し た場合、利用者は当社の損害を賠償し、それに伴う費用(訴訟費用、弁護士費用、その他 費用を含む)を支払うことを保証するものをいいます。<br />
                ① 本商品が利用者と第3者との個別合意で譲渡制限がないこと。<br />
                ② 本商品が第3者の担保権の対象となっていないこと。<br />
                ③ 本商品が差押、仮差押、仮処分を受けていない、または受ける予定が無いこと。<br />
                ④ 本商品に対するクレジットなどの債権と相殺等、一切の抗弁が存在せず、ピクチェンジの返還期日(以下「期日」といいます)の引渡しを拒絶される見込みがないこと。<br />
                ⑤ 本商品に関して、利用者が本サービに登録した情報以外に何らの特約もないこと。<br />
                ⑥ 本商品が本サービス利用時点で正に存在すること。<br />
                ⑦ 本商品が本サービス利用時点で登録された現在価値に著しい変動を齎す瑕疵がないこと。<br />
                ⑧ 本商品が真に本物であり、本商品の製造元や販売元の商標権等を侵害するものではないこと。<br />
                ⑨ 本商品が故買品ではないこと。<br />
                ⑩ その他、本規約で合意した条件、申込時に誓約した内容を満たすこと。<br />
                3. 利用者とは、本商品を売渡す法人および個人をいいます。<br />
                4. 加盟店とは、本商品を買受け、売りピクチェンジを行う古物商をいいます。<br />
                5. 資格を持つとは、利用者が本商品を正に有し、真の商品であり、正当に所有し、登録する現在価値と著しい変動がないことを保証できることをいいます。<br />
                6. 評価および確認とは、利用者の資格確認および本サービスの利用履歴などを評価し、確認することをいいます。<br />
                7. 仮査定とは、利用者が開示する本商品画像データで査定できる仮の査定額をいいます。<br />
                8. 本査定とは、利用者が返還する本商品を鑑定して査定する正の査定額をいいます。<br />
                9. 善管注意義務とは、加盟店(当社を含む)から、ピクチェンジ期間中に本商品を利用者の責任において、本サービス利用時点そのままの状態で利用することをいいます。<br />
                10. 電子的にとは、本サービスが通話、通信によって非対面で行われることをいいます。<br />
                11. 提供するとは、本サービスは利用者と加盟店(当社を含む)との2者間取引であることをいいます。<br />
                12. 振込保証とは、買い取り金額を利用者の指定口座に確実に払い込むことをいいます。<br />
                13. 送金手数料とは、利用者が買い取り金額の払い込みを受ける際に差引支払う一律500円の銀行送金手数料をいいます。<br />
                14.マイページとは、利用者が入会にあたり本規約に同意する際に任意に記入した個人情報や取引履歴などを登録したデータベースをいいます。<br />
                15.(会員ID)とは、当社がマイページに基づき登録利用者に割り振り、登録利用者本人のみが利用できる番号またはコードをいいます。<br />
                16. (覚書)とは、本規約に基づく利用者IDを記載し、買戻し金額、返還期日などを定める契約書をいいます。<br />
                17. (ID返戻)とは、振込人の欄に6桁からなる会員番号IDを入力して返戻することをいいます。
              </Text>
            </Section>
            <Section>
              <Text>
                第2条（⽬的）<br />
                1. 本サービスは、利用者が所有する本商品の経済的処分によって社会活動を継続する上で必要な資金を低コストで早期に得ることと、買取り後に使用を認めることで、利用者の本商品に対する心理的処分を行うことができるように期間の延長を目的とします。<br />
                2. 利用者は、本規約の同意締結においては事実のみを申出、本規約に基づき本サービスを健全に利用します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第3条（利用者の登録資格事項）
              </Text>
              <Text style={sectionBody}>
                1. 犯罪収益移転防止に基づき、個人会員には以下の個人情報をご登録いただきます。<br />
                ① 本人確認のため自動車運転免許証等の顔画像付きの公的な身分証明書<br />
                ② 本人名義、身内や家族の携帯電話会社のマイページ(契約)情報
                ③ 本人名義のLINEアカウントID情報<br />
                ④ 買い取り金額の払い込みに必要な本人名義の銀行口座情報<br />
                ⑤ ご本人様と必ず連絡が取れるご親族の方の携帯電話番号<br />
                ⑥ ご本人様と必ず連絡が取れる勤務先情報<br /> <br />

                2. 法人会員には以下の法人情報および取引責任者個人情報をご登録いただきます。<br />
                ① 法人登記簿謄本<br />
                ② 取引責任者確認のため自動車運転免許証などの顔画像付きの公的な身分証明書<br />
                ③ 取引責任者名義の携帯電話会社のマイページ(契約)情報<br />
                ④ 取引責任者名義のLINEアカウントID情報<br />
                ⑤ 買い取り金額の払い込みに必要な法人名義の銀行口座情報<br />
                ⑥ 取引責任者と必ず連絡が取れるご親族の方の携帯電話番号<br />
                ⑦ 取引責任者と必ず連絡が取れる勤務先情報
              </Text>
            </Section>
            <Section>
              <Text>
                第4条（入会について）<br />
                1. 入会を希望する者以下「申込者」といいます。直接当社に対して入会の申込以下「申込」といいます。<br />
                2. 当社は、自己の自由な判断によって、当該申込および再申込を承諾並びに拒否することができます。<br />
                3. 当社は、申込に対する諾否の判断に必要な情報を申込者に求めることができます。<br />
                4. 当社が、申込を承諾し、本規約を同意締結した時点で当該申込者は会員となります。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第5条（虚偽記載の禁止について）
              </Text>
              <Text style={sectionBody}>
                1. 利用者は本サービスへ虚偽を登録して加盟店および当社に損害を与えてはなりません。<br />
                2. 利用者は本サービス利用から前後2ヶ月の間は係争、破産、債務整理、離職、休業等 (以下「資格喪失」といいます)の影響で本契約が不履行になるおそれがないことを保証します。<br />
                3. 利用者は資格喪失のおそれが少しでもある場合、当社に対し誠実に真実を申出て、速やかに会員資格を停止し、本サービスを利用してはなりません。<br />
                4. 利用者は当社に対して資格喪失の可能性を隠し、本サービスを利用し、加盟店および当 社から売渡し金額の払い込みを受けながら、本商品の返還を免れようとして、弁護士や司法書士等を利用計画し、故意、過失を問わず、当社に損害を与えてはなりません。<br />
                5. 利用者は、本サービスの利用にあたり、関連諸法規を遵守するものとし、本サービスの信用、名誉を毀損することのないよう努めなければなりません。<br />
                6. 利用者は返還期日までに本商品の現在価値に著しい変動があった場合は、その知りえるものを調査し、当社に報告しなければなりません。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第6条（二重譲渡禁止等）
              </Text>
              <Text style={sectionBody}>
                利用者は、本商品を当社以外の第3者に二重に譲渡(譲渡担保を含む)してはなりません。
              </Text>
            </Section>
            <Section>
              <Text>
                第7条（現在価値の登録）<br />
                利用者が当社に登録できる本商品の現在価値は、当社以外の買い取り事業者が査定する現在価値未満であり、本商品に著しい瑕疵がない場合、返還時に利用者の売渡し代金を清算できる金額でなければならない。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第8条（期間利益の喪失）
              </Text>
              <Text style={sectionBody}>
                1. 本サービスの利用期間中に利用者と連絡がとれない、または返還期日の払い込みが遅れる場合に、当社の判断によって本サービスの利用を停止します。<br />
                2. 連絡が取れない場合には、直ちに利用者は受け取った代金の全額を当社の指定する銀行口座に払い込まなければなりません。なお、銀行送金手数料は利用者が負担します。<br />
                3. 違反の場合には、直ちに利用者は本サービス利用時点、そのままの本商品を加盟店に引き渡さなければなりません。なお、発送にかかる費用は利用者が負担します。
              </Text>
            </Section>
            <Section>
              <Text>
                第9条（支払方法）<br />
                1. ピクチェンジの利用期間中は利用者が指定する毎月同日(土日祝日の場合は前営業日) までに、別途指定する口座に振込支払うものとします。<br />
                2. 振り込みにかかる銀行送金手数料は利用者負担とします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第10条（本サービスの停止又は中断）
              </Text>
              <Text style={sectionBody}>
                1. 当社は、以下の各号に該当する場合には、本サービスの一部および全部を停止する事ができるものとします。<br />
                ① 本サービスの適正な運用のため、点検、補修、改修が必要と当社が認めた場合。<br />
                ② 本サービスに使用する通信回線が輻輳による使用困難および使用不能な場合。<br />
                2. 当社が前項に基づきサービスを停止する場合には、予め、その理由、実施期日および期間を利用者に通知します。但し、緊急の場合、火災、停電、地震等の天災、その他の不可抗力による場合は除きます。<br />
                3. 当社は、本サービスにおいて情報伝送に用いる第3者の回線および通信に起因する、並びに利用者の機器等に起因する通信不良、遅延、誤送等の本サービスの運営障害について一切の責を負いません。
              </Text>
            </Section>
            <Section>
              <Text>
                第11条（反社会的勢力の排除）<br />
                利用者は、次の各号のいずれにも該当しないことを表明し，かつ将来にわたっても該当しないことを確約します。<br />
                1 暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロおよび特殊知能暴力集団等、その他これらに準ずる者(以下「暴力団員等」といいます)<br />
                2 暴力団員等が経営を実質的に支配および関与していると認められる関係を有すること<br />
                3 暴力団員等を利用して、自己および第三者の利益を図る目的並びに第三者に損害を加える目的を有すること<br />
                4 暴力団員等に対する資金等の提供および便宜を供与するなどの関係を有すること<br />
                5 役員および実質的経営者が暴力団員等と関係を有すること
              </Text>
            </Section>
            <Section>
              <Text>
                第12条（秘密保持および個人情報保護）<br />
                1. 当社は、本サービス利用の検討や利用を通じて知り得た利用者の法人および個人情報の秘密を守るものとします。<br />
                2. 当社が業務を第3者に委託することにより、当該第3者が前項の秘密事項に接することになる場合は、当該第3者に対して、本条と同様の守秘義務を課すと共にこれを遵守させることとします。<br />
                3. 当社は、本サービスの運用にあたって取得する利用者の法人および個人情報について、個人情報の保護に関する法律(平成15年法律第57号)の規定、その他の関連法令に準拠して適切に保護するものとします。<br />
                4. 前項の規定にかかわらず、次の各号のいずれかに該当することを、開示を受けた当社が証明することのできる情報は、その秘密情報には含まれないものとします。<br />
                ① 開示の時点で既に公知の情報、その後開示を受けた当社の責によらずして公知となった情報<br />
                ② 開示を受けた当社が第3者から秘密保持義務を負うことなく正当に入手した情報<br />
                ③ 開示の時点で既に開示を受けた当社が保有している情報<br />
                ④ 開示を受けた当社が、開示された情報によらずして独自に開発した情報<br />
                ⑤ 開示した当社が、第3者に対し秘密保持義務を課すことなく開示した情報<br />
                ⑥ 裁判所、官公庁、弁護士会その他の公的機関から法令に基づいて開示を求められた情報 (但し、開示前にLINEアカウントで利用者に通知するものとします)
              </Text>
            </Section>
            <Section>
              <Text>
                第13条（会員登録の抹消および再登録）<br />
                1. 利用者はいつでも会員登録を抹消し、退会することができます。<br />
                2. 前項の会員登録を抹消した利用者はいつでも新規入会申込ができます。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第14条（通知）
              </Text>
              <Text style={sectionBody}>
                1. 当社から利用者に対する通知は、別段の定めのある場合を除き、利用者が当社に登録したLINEアカウントにより行います。但し、通信障害等やむをえない事態が発生した場合は他の適当な方法で行います。<br />
                2. 当社から利用者への通知は、利用者のLINEアカウントへのトーク送信をもって通知されたものとします。但し、前項但し書きの場合を除きます。<br />
                3. 利用者は、当社からの通知内容を確認するため、登録しているLINEアカウントのトークを返還および返戻期日の3日前および前日において毎日1回は閲覧できる体制を維持するものとします。利用者において通信障害等やむをえない場合には、代替の通信手段を当社に通知することとします。<br />
                4. 利用者は、当社に登録したマイページ情報その他の重要な事項を変更する場合は、事前に届出なければなりません。<br />
                5. 利用者は、前項の届出が無いために、当社からの通知、その他のものが延着または不到達となったとき場合には、それらが通常到着すべきときに到着したものとみなされることに同意したものとみなします。<br />
                6. 当社と利用者との通知等の通信は日本語で行います。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第15条（損害賠償）
              </Text>
              <Text style={sectionBody}>
                1. 利用者が本規約に違反し加盟店および当社に損害を与えた場合、加盟店および当社が被った直接且つ現実の損害、また機会損失等の間接損害含め利用者が賠償するものとします。<br />
                2. 本サービスの利用者が被った直接且つ現実の損害、また機会損失等の間接損害含め、当社が負担する損害賠償責任は一切ありません。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第16条（会員資格の解除）
              </Text>
              <Text style={sectionBody}>
                当社は、利用者が以下の各号のいずれかに該当する場合には、事前の通知および催告なくして、直ちに会員資格を解除することができるものとします。<br />
                ① 入会時に登録したLINEアカウントを維持、管理しない場合<br />
                ② 本規約に定める義務に違反し、当社から相当の期間を定めて催告したにもかかわらず改善されなかった場合<br />
                ③ 破産、債務整理等の申立および申し立てられた場合<br />
                ④ 総合会費の払い込み、商品の返還もしくは売却代金の返戻を一月以上遅滞した場合<br />
                ⑤ 財政状態が著しい悪化およびそのおそれがあると認められる相当の事由がある場合<br />
                ⑥ その他、利用者が反社会的勢力と関係した場合など本サービスの運営上望ましくないと当社が判断した場合
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第17条（有効期限）
              </Text>
              <Text style={sectionBody}>
                本規約の有効期間は、利用者が本サービスの会員である期間とします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第18条（加盟店の廃止）
              </Text>
              <Text style={sectionBody}>
                1. 当社は利用者への事前通知なく、加盟店の廃止を行うことができます。<br />
                2. 他に取引できる加盟店がいない場合、利用者は加盟店との取引はできません。<br />
                3. 利用者が既に加盟店との取引期間中は、当該利用期間の終了を待って加盟店との取引を終了します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第19条（免責について）
              </Text>
              <Text style={sectionBody}>
                利用者は自己の責任において本サービスを利用するものであり、利用者が本商品の売渡しによって資金を調達する目的に対し、当社は必ずしも、利用者の期待する資金調達の成果を保証するものではありません。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第20条（違反行為の開示について）
              </Text>
              <Text style={sectionBody}>
                1. 利用者が資格喪失を隠して取引した場合、当社は直ちに利用者の会員資格を停止し、同情報を利用することで、2次被害が出ないようにします。<br />
                2. 利用者は、第1項によって将来的にデジタルサービスや金融サービスの提供を受けることができなくなる恐れがあることに同意します。<br />
                3. 利用者が加盟店および当社の損害を回復する場合、第1項を停止することができます。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第21条（制限の禁止）
              </Text>
              <Text style={sectionBody}>
                当社は申込者に対し、正当な理由なく会員登録を拒絶したり、登録を要求したり、登録抹消を拒んだり、再登録を要求したり、申込者の自由意志を阻む制限を行いません。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第22条（効力の発生）
              </Text>
              <Text style={sectionBody}>
                1. 本規約は、令和5年4月1日制定し、同日より適用されます。<br />
                2. 利用者に対する本規約の効力は、利用者が本規約に同意締結後に、第9条に納付した時および利用者の口座に別紙覚書記載の金額、および送金手数料を差引いた額の振込みを完了した時に発生します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第23条（準拠法）
              </Text>
              <Text style={sectionBody}>
                本契約は、日本国の法律に基づき解釈・実施されるものとします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第24条（規約の変更）
              </Text>
              <Text style={sectionBody}>
                1. 当社は、監督官庁の指導を受けた場合などのほか、ピクチェンジの運営上で支障がある場合、予告なく、本規約の内容の変更を、当社のウェブサイト上に掲載し、本規約を変更することができるものとします。<br />
                2. 本規約の変更に不服のある利用者は、当社にLINEで退会を申し入れることにより、会員登録を抹消することができます。<br />
                3. 利用者が退会の申し入れをしなかった場合には、利用者は変更された規約を承認したものとみなします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第25条（専属的合意管轄裁判所）
              </Text>
              <Text style={sectionBody}>
                当社と利用者の間で、本規約に関して訴訟の必要が生じた場合は、訴額に応じて、簡易裁判所あるいは地方裁判所を第一審の専属的合意管轄裁判所とします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第26条（退会後の取扱）
              </Text>
              <Text style={sectionBody}>
                1. 退会した場合(会員資格の解除を含む)であっても、既に発生している取引は、商品の返還および売却代金の返戻履行完了まで有効に存続するものとします。<br />
                2. 退会の際には、利用者の個人情報等は当社の判断により処分するものとします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第27条（監督官庁の指導）
              </Text>
              <Text style={sectionBody}>
                当会社は、なんらかの事由で、監督官庁からの指導がある場合は、本サービスを停止することができます。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第28条（協議事項）
              </Text>
              <Text style={sectionBody}>
                本規約に定めのない事項、または本規約の解釈に疑義が生じた場合には、当社と利用者は、信義誠実の原則に則り協議するものとします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionBody}>
                以上、本規約同意成立の証として、本書を作成して、利用者が電子署名の上、利用者の指定したメールアドレスに1通を送付し、1通を当社のメールサーバーに保存します。
              </Text>
            </Section>
            <Section>
              <Text>
                ⻄暦 {createdDateJa}
              </Text>
            </Section>
            <Section>
              <Text>（甲）</Text>
              <Text>
                会員番号 {props.userId}<br />
                現住所 〒{props.postalCode} {props.prefectureName} {props.address}<br />
                ⽒名 {props.userFullName}<br />
                携帯番号 {props.phoneNumber}<br />
                緊急連絡先① 名 {props.emergencyContactName} TEL {props.emergencyContactNumber} 続柄 {props.emergencyContactRelation}
              </Text>
            </Section>
            <Section>
              <Text>（⼄）</Text>
              <Text>
                名称  合同会社アトラス
              </Text>
            </Section>
            <Section>
              <Text>
                1 第三者債務者の表⽰<br />
                取引先・勤務先の名称 {props.clientName}<br />
                取引先・勤務先の⽀店・部署等 {props.clientBranch}<br />
                取引先・勤務先の所在地 〒{props.clientPostalCode} {props.clientPrefectureName} {props.clientAddress}<br />
                取引先・勤務先のTEL {props.clientPhoneNumber}
              </Text>
            </Section>
            <Section>
              <Text>
                2 本件債権の表⽰<br />
                (1) 商品の種類 携帯・家電・ブランド品・時計の売掛債権<br />
                (2) 譲渡する商品の売掛債権の対象 支払期日に受取る商品の売掛債権の発生期間とします<br />
                (3) ⽀払期⽇（期限）：{props.liquidationDateStringJa}<br />
                (4) 譲渡⾦額 上記により特定される売掛債権額のうち ⾦ {props.formattedTransferAmount}円
              </Text>
            </Section>
            <Section>
              <Text>
                3 代⾦の表⽰<br />
                ⾦ {props.formattedReceiveAmount}円<br />
              </Text>
            </Section>
            <Section>
              <Text>
                4 お客様情報<br />
                振込先⾦融機関名→ {props.bankName}<br />
                ⽀店名 {props.bankBranch}<br />
                ⼝座種⽬ {props.bankTypeName}<br />
                ⼝座番号 {props.bankNumber}<br />
                ⼝座名義⼈【カタカナ】 {props.bankHolderName}
              </Text>
            </Section>
            <Section>
              <Text>
                ※ 確認事項<br />
                利用者は、本規約が商品の売買および取引についての定めであることを理解して同意し、利用にあたっては異議を申し立てず、自らの意志で入会するものであり、かつ電子契約法3条に基づき、甲は自ら入力した(金額および日付を除く)内容に操作ミスによる誤入力があっても内容の確認措置は不要であり、本契約に記載される甲の情報および申込内容に間違いがないことを確認し、同意して電子署名します。
              </Text>
              <Text>
                確認者⽒名 {props.userFullName}
              </Text>
            </Section>
            <Section>
              <Text>
                【同意する: わたしは「プライバシーポリシー」「情報利⽤に関する規約」を確認・同意します。】<br/>
                ☑︎
              </Text>
              <Text>
                【同意する: 利用者は、本規約が商品の売買および取引についての定めであることを理解し、同意し、利用にあたっては異議を申し立てず、自らの意志で入会するものであり、申込内容に間違いがないことを確認し、同意して電子署名します。】<br/>
                ☑︎
              </Text>
            </Section>
            <Text>利用者の⽣年⽉⽇ {props.birthStringJa}</Text>
          </Container>
        </Body>
      </Html>
  );
};

const main = {
  backgroundColor: '#ffffff',
  color: '#333',
};

const indentText = {
  textIndent: '1em'
}
const sectionHead = {
  marginBottom: 0
}
const sectionBody = {
  marginTop: 0,
  textIndent: '1em'
}

const container = {
  paddingLeft: '12px',
  paddingRight: '12px',
  margin: '0 auto',
};

const h1 = {
  color: '#333',
  fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '40px 0',
  padding: '0',
};

export default MobasokuNewContractEmailTemplate
