import {useRecoilValue} from "recoil";
import {TableHeader} from "../Elements/Table/TableHeader";
import {TableCell} from "../Elements/Table/TableCell";
import clsx from "clsx";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import functions from "../../libs/firebase";
import LoadingOverlay from "react-loading-overlay-ts";
import { render } from '@react-email/render';
import {ReContractFormInputsSelector} from "../../types/ReContractFormInputs";
import {ReContractFormInputSelector} from "../../atoms/ReContractFormInputState";
import QuickKaitoriReContractEmailTemplate from "../../emails/QuickKaitori/ReContractEmailTemplate";
import {Helmet} from "react-helmet-async";
import MobasokuReContractEmailTemplate from "../../emails/Mobasoku/ReContractEmailTemplate";

const ReContractFormConfirm = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const reContractFormInputState = useRecoilValue(ReContractFormInputSelector) as ReContractFormInputsSelector
    const sendEmail = httpsCallable(functions, 'sendmail');
    const [isError, setIsError] = useState(false)

    const onSubmit = () => {
        setLoading(true)
        setIsError(false)
        const to = reContractFormInputState.email
        if (reContractFormInputState.contract === 'quick-kaitori') {
            const html = render(<QuickKaitoriReContractEmailTemplate {...reContractFormInputState} />);
            const text = render(<QuickKaitoriReContractEmailTemplate {...reContractFormInputState} />, {
                plainText: true
            });
            const subject = "【クイック買取覚書】" + reContractFormInputState.userFullName
            const from = 'クイック買取'
            send(html, text, to, subject, from)
        }

        if (reContractFormInputState.contract === 'mobasoku') {
            const html = render(<MobasokuReContractEmailTemplate {...reContractFormInputState} />);
            const text = render(<MobasokuReContractEmailTemplate {...reContractFormInputState} />, {
                plainText: true
            });
            const subject = "【クイック買取覚書】" + reContractFormInputState.userFullName
            const from = 'クイック買取'
            send(html, text, to, subject, from)
        }

        function send(html: string, text:string, to:string, subject: string, from:string) {
            sendEmail({html, text, to, subject, from})
                .then(() => {
                    setLoading(false)
                    navigate('/complete', {replace: true})
                })
                .catch((_) => {
                    setLoading(false)
                    setIsError(true)
                })
        }
    }

    return (
        <>
            <Helmet>
                {(reContractFormInputState.contract === 'quick-kaitori') && (
                    <title>クイック買取</title>
                )}
                {(reContractFormInputState.contract === 'mobasoku') && (
                    <title>クイック買取</title>
                )}
            </Helmet>
            <LoadingOverlay spinner text='データを送信しています...' active={loading}>
                <div className="container prose mx-auto max-w-none">
                    <div className="px-4 max-w-5xl mx-auto bg-white border lg:px-24 lg:mt-16">
                        <section>
                            <h2>再契約フォーム</h2>
                            <p>迷惑メール設定の場合はinfo@quickkaitori.netからのメールを受信できるように設定変更をお願いいたします。</p>
                            <p>内容をご確認いただき、お間違いがなければ一番下のボタンを押してください。</p>
                        </section>
                        {isError && (
                            <section>
                                <div className="text-red-500 bg-red-50 border-2  px-4 py-1 border-red-500">
                                    エラーが発生しました。<br />
                                    ご迷惑をお掛けいたしますが、現在対応中ですのでしばらくお時間を置いて操作を再開してください。
                                </div>
                            </section>
                        )}
                        <section>
                            <h3 className="border-b-2 border-black mb-6">１．ご本人様（お名前など）について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="会員番号"  />
                                    <TableCell>
                                <span>
                                    {reContractFormInputState.userId}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="原契約締結期日及び時刻"  />
                                    <TableCell>
                                <span>
                                    {reContractFormInputState.contractDateTimeStringJa}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="支払期日"  />
                                    <TableCell>
                                <span>
                                    {reContractFormInputState.paymentDueStringJa}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="お名前(全角)"  />
                                    <TableCell>
                                <span>
                                    {reContractFormInputState.lastName} {reContractFormInputState.firstName}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="携帯番号"  />
                                    <TableCell>
                                <span>
                                    {reContractFormInputState.phoneNumber}
                                </span>
                                    </TableCell>
                                </tr>

                                <tr>
                                    <TableHeader label="メールアドレス"  />
                                    <TableCell>
                                <span>
                                    {reContractFormInputState.email}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="ご住所"  />
                                    <TableCell>
                                <span>
                                    {reContractFormInputState.postalCode}<br />
                                    {reContractFormInputState.prefectureName}<br />
                                    {reContractFormInputState.address}
                                </span>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <div className="text-center">
                                <div className="mb-12">
                                    <button
                                        onClick={onSubmit}
                                        className={clsx(
                                            'bg-[#9b8d77] text-white',
                                            'border-b-2 border-b-black',
                                            'rounded-md',
                                            'w-64 h-12',
                                            'cursor-pointer',
                                            'hover:opacity-70',
                                            'disabled:bg-gray-400 disabled:cursor-not-allowed'
                                        )}
                                        type="button"
                                    >上記内容にて契約書に署名</button>
                                </div>
                                <div className="mb-12">
                                    <Link to="/re-contract" className="text-red-600 font-bold">入力をやり直す</Link>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default ReContractFormConfirm
