import {Control, FieldPath, FieldValues} from "react-hook-form";
import {InputHTMLAttributes} from "react";
import {SelectFieldControl} from "./SelectFieldControl";
import {range} from "../../utils/range";

type Props<T extends FieldValues> = {
    control: Control<T>
    name: FieldPath<T>

} & InputHTMLAttributes<HTMLSelectElement>

export const HourSelectFieldControl = <T extends FieldValues>({control, name, className, autoFocus, placeholder} : Props<T>) => {

    const to = 23
    const from =  0

    return (
        <SelectFieldControl
            control={control}
            name={name}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={className}
        >
            <option value="">選択してください</option>
            {range(from, to).map((hour, idx) => {
                return <option key={idx} value={hour}>{hour}時</option>
            })}
        </SelectFieldControl>
    )
}