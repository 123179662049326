import {atom, selector} from "recoil";
import prefectureJson from "../json/prefecture.json"
import {format} from "date-fns";
import {ReContractFormInputs} from "../types/ReContractFormInputs";
export const ReContractFormInputState = atom<ReContractFormInputs>({
    key: 'ReContractFormInputState',
    default: {
        userId: '',
        contractDate: undefined,
        contractTime: {
            hour: '0',
            minute: '0'
        },
        paymentDue: undefined,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        emailConfirmation: '',
        postalCode: '',
        prefecture: '',
        address: '',
        agreements1: false,
        agreements2: false,
        contract: 'quick-kaitori'
    }
})

export const ReContractFormInputSelector = selector({
    key: "ReContractFormInputSelector",
    get: ({get}) => {
        const reContractFormInputState = get(ReContractFormInputState)
        const prefectures = prefectureJson.prefectures
        const prefectureName = prefectures.find(prefecture => prefecture.code === parseInt(reContractFormInputState.prefecture))?.name
        const userFullName = reContractFormInputState.lastName + ' ' + reContractFormInputState.firstName
        const contractDateTimeStringJa =
            format(reContractFormInputState.contractDate ?? new Date(), 'yyyy年M月d日') + ' ' +
            reContractFormInputState.contractTime.hour + '時' +
            reContractFormInputState.contractTime.minute + '分'
        const paymentDueStringJa = format(reContractFormInputState.paymentDue ?? new Date(), 'yyyy年M月d日')

        return {
            ...reContractFormInputState,
            prefectureName,
            contractDateTimeStringJa,
            paymentDueStringJa,
            userFullName
        }
    }
})