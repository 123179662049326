import {Control, FieldPath, FieldValues} from "react-hook-form";
import {InputHTMLAttributes} from "react";
import {SelectFieldControl} from "./SelectFieldControl";
import {range} from "../../utils/range";

type Props<T extends FieldValues> = {
    control: Control<T>
    name: FieldPath<T>

} & InputHTMLAttributes<HTMLSelectElement>

export const YearSelectFieldControl = <T extends FieldValues>({control, name, className, autoFocus, placeholder} : Props<T>) => {

    const now = new Date()
    const to = now.getFullYear()
    const from =  to - 100

    return (
        <SelectFieldControl
            control={control}
            name={name}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={className}
        >
            <option value="">年を選択</option>
            {range(from, to).reverse().map((year, idx) => {
                return <option key={idx} value={year}>{year}年</option>
            })}
        </SelectFieldControl>
    )
}