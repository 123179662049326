import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NewContractForm from "./components/Contents/NewContractForm";
import NewContractFormConfirm from "./components/Contents/NewContractFormConfirm";
import ContractComplete from "./components/Contents/ContractComplete";
import {ScrollTop} from "./components/Layouts/ScrollTop";
import {ReContractForm} from "./components/Contents/ReContractForm";
import ReContractFormConfirm from "./components/Contents/ReContractFormConfirm";

const App = () => {
    return (
        <BrowserRouter>
            <ScrollTop />
            <Routes>
                <Route path={`/`} element={<NewContractForm />} />
                <Route path={`/confirm`} element={<NewContractFormConfirm />} />
                <Route path={`/complete`} element={<ContractComplete />} />
                <Route path={`/contract`} element={<NewContractForm />} />
                <Route path={`/contract/confirm`} element={<NewContractFormConfirm />} />
                <Route path={`/re-contract`} element={<ReContractForm />} />
                <Route path={`/re-contract/confirm`} element={<ReContractFormConfirm />} />

                <Route path={`/mobasoku`} element={<NewContractForm />} />
                <Route path={`/contract/mobasoku`} element={<NewContractForm />} />
                <Route path={`/contract/confirm/mobasoku`} element={<NewContractFormConfirm />} />
                <Route path={`/re-contract/mobasoku`} element={<ReContractForm />} />
                <Route path={`/re-contract/confirm/mobasoku`} element={<ReContractFormConfirm />} />
                <Route path={`/complete/mobasoku`} element={<ContractComplete />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
