import {atom, selector} from "recoil";
import {NewContractFormInputs} from "../types/NewContractFormInputs";
import prefectureJson from "../json/prefecture.json"
import {format} from "date-fns";
export const NewContractFormInputState = atom<NewContractFormInputs>({
    key: 'NewContractFormInputState',
    default: {
        userId: '',
        firstName: '',
        lastName: '',
        birth: {
            year: '',
            month: '',
            day: ''
        },
        phoneNumber: '',
        email: '',
        emailConfirmation: '',
        postalCode: '',
        prefecture: '',
        address: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        emergencyContactRelation: '',
        clientName: '',
        clientBranch:'',
        clientPostalCode:'',
        clientPrefecture:'',
        clientAddress:'',
        clientPhoneNumber:'',
        liquidationDate: undefined,
        transferAmount: '',
        receiveAmount: '',
        bankName: '',
        bankBranch: '',
        bankType: '1',
        bankNumber: '',
        bankHolderName: '',
        agreements: false,
        compliance: false,
        contract: "quick-kaitori"
    }
})

export const NewContractFormInputSelector = selector({
    key: "NewContractFormInputSelector",
    get: ({get}) => {
        const newContractFormInputState = get(NewContractFormInputState)
        const prefectures = prefectureJson.prefectures
        const prefectureName = prefectures.find(prefecture => prefecture.code === parseInt(newContractFormInputState.prefecture))?.name
        const clientPrefectureName = prefectures.find(prefecture => prefecture.code === parseInt(newContractFormInputState.clientPrefecture))?.name
        const bankTypeName = (newContractFormInputState.bankType === "1") ? '普通' : '当座'
        const birthStringJa = format(new Date(
            parseInt(newContractFormInputState.birth.year),
            parseInt(newContractFormInputState.birth.month) - 1,
            parseInt(newContractFormInputState.birth.day)
        ), 'yyyy年M月d日')
        const liquidationDateStringJa = format(newContractFormInputState.liquidationDate ?? new Date(), 'yyyy年M月d日')
        const formattedTransferAmount = new Intl.NumberFormat('ja-JP')
            .format(parseInt(newContractFormInputState.transferAmount))
        const formattedReceiveAmount = new Intl.NumberFormat('ja-JP')
            .format(parseInt(newContractFormInputState.receiveAmount))
        const userFullName = newContractFormInputState.lastName + ' ' + newContractFormInputState.firstName

        return {
            ...newContractFormInputState,
            prefectureName,
            clientPrefectureName,
            bankTypeName,
            birthStringJa,
            liquidationDateStringJa,
            formattedTransferAmount,
            formattedReceiveAmount,
            userFullName
        }
    }
})