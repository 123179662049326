import {
  Html,
  Head, Body, Container, Section, Row, Column, Heading,Text
} from "@react-email/components";
import { format } from 'date-fns'
import {ReContractFormInputsSelector} from "../../types/ReContractFormInputs";

type Props = ReContractFormInputsSelector

const QuickKaitoriReContractEmailTemplate = (props: Props) => {
  const now = new Date()
  const createdAt = format(now, 'yyyy-MM-dd  HH時mm分')
  const createdDateJa = format(now, 'yyyy年M月d日')

  return (
      <Html lang="ja">
        <Head />
        <Body style={main}>
          <Container style={container}>
            <Section>
              <Row>
                <Column align="center">
                  <Heading style={h1}>「クイック買取」覚書</Heading>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text>覚書送付⽇時：{createdAt}</Text>
                </Column>
              </Row>
            </Section>
            <Section>
              <Text style={indentText}>
                {props.contractDateTimeStringJa}に甲⼄間で締結した⽴替債権取引契約書（以下「原契約書」といいます）について、原契約書記載の甲（以下「甲」といいます）と原契約書記載の⼄（以下 「⼄」といいます）は、以下の通り期⽇変更（以下「本契約」といいます）することを合意します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第１条（⽀払期⽇の変更）
              </Text>
              <Text style={sectionBody}>
                本契約の締結に伴い原契約書（⼄）第２条本件債権の表⽰第３項⽀払期⽇を「{props.paymentDueStringJa}」に変更します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第２条（原契約書の適⽤）
              </Text>
              <Text style={sectionBody}>
                本契約において、この覚書に定めのない事項については原契約書のとおりとします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第３条（現契約書の事実）
              </Text>
              <Text style={sectionBody}>
                甲は⼄に対し原契約書に甲が記載した内容が事実であり、同内容に変更がないことを約します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第４条（効⼒発⽣⽇）
              </Text>
              <Text style={sectionBody}>
                本契約の効⼒は、原契約書に記載する甲の指定する銀⾏⼝座に同記載の代⾦額を⼄が送⾦することで、{createdDateJa}より発⽣します。
              </Text>
            </Section>
            <Section>
              <Text>
                本契約の成⽴を証するため、本書２通を作成し甲が電⼦署名の上、各１通ずつ保有するものとします。
              </Text>
            </Section>
            <Section>
              <Text>（甲）</Text>
              <Text>
                会員番号 {props.userId}<br />
                現住所 〒{props.postalCode} {props.prefectureName} {props.address}<br />
                ⽒名 {props.userFullName}<br />
                携帯番号 {props.phoneNumber}<br />
              </Text>
            </Section>
            <Section>
              <Text>
                【同意する: ⼄は甲への送⾦によって本契約に同意します。】<br/>
                ☑︎
              </Text>
              <Text>
                【同意する: 甲は原契約書締結時に同意した各種の内容を、本覚書締結においても同意します。】<br/>
                ☑︎
              </Text>
            </Section>
          </Container>
        </Body>
      </Html>
  );
};

const main = {
  backgroundColor: '#ffffff',
  color: '#333',
};

const indentText = {
  textIndent: '1em'
}
const sectionHead = {
  marginBottom: 0
}
const sectionBody = {
  marginTop: 0,
  textIndent: '1em'
}

const container = {
  paddingLeft: '12px',
  paddingRight: '12px',
  margin: '0 auto',
};

const h1 = {
  color: '#333',
  fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '40px 0',
  padding: '0',
};

export default QuickKaitoriReContractEmailTemplate
