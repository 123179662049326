import React from "react";
import {RequiredBadge} from "../RequiredBadge";

type Props = {
    label: string
    required?: boolean
}

export const TableHeader = ({label, required = false} : Props) => {
    return (
        <th className="bg-zinc-100 align-middle p-2 block md:table-cell md:w-2/6">
            {required && <RequiredBadge />}
            <span className="ml-2 text-sm">
                {label}
            </span>
        </th>
    )
}