import {useRecoilValue} from "recoil";
import {ReContractFormInputState} from "../atoms/ReContractFormInputState";
import {useEffect, useMemo} from "react";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {ReContractFormInputs} from "../types/ReContractFormInputs";
import {ReContractFormSchema} from "../schemas/ReContractFormSchema";

export const useReContractForm = () => {
    const reContractFormInputState = useRecoilValue(ReContractFormInputState)
    const defaultValues = useMemo(() => reContractFormInputState, [reContractFormInputState])

    const {
        control,
        handleSubmit: handleSubmitOriginal,
        formState: {errors, isValid},
        reset,
    } =useForm<ReContractFormInputs>({
        defaultValues,
        resolver: zodResolver(ReContractFormSchema)
    })

    useEffect(() => {
        reset(reContractFormInputState)
    }, [reContractFormInputState, reset])


    const handleSubmit = (
        onValid: SubmitHandler<ReContractFormInputs>,
        onInvalid?: SubmitErrorHandler<Error>
    ) => handleSubmitOriginal(onValid, onInvalid)

    return {
        control,
        errors,
        handleSubmit,
        isValid
    }
}