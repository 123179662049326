import {
  Html,
  Head, Body, Container, Section, Row, Column, Heading,Text
} from "@react-email/components";
import {NewContractFormInputsSelector} from "../../types/NewContractFormInputs";
import { format } from 'date-fns'

type Props = NewContractFormInputsSelector

const QuickKaitoriNewContractEmailTemplate = (props: Props) => {
  const now = new Date()
  const createdAt = format(now, 'yyyy-MM-dd  HH時mm分')
  const createdDateJa = format(now, 'yyyy年M月d日')

  return (
      <Html lang="ja">
        <Head />
        <Body style={main}>
          <Container style={container}>
            <Section>
              <Row>
                <Column align="center">
                  <Heading style={h1}>「クイック買取」契約書</Heading>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text>契約書送付⽇時：{createdAt}</Text>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text>
                    甲（譲渡⼈）{props.userFullName}<br />
                    ⼄ （譲受⼈）クイック買取
                  </Text>
                </Column>
              </Row>
            </Section>
            <Section>
              <Text style={indentText}>
                甲と乙は、甲が乙に対し、本契約に記載の第3債務者(以下「第3債務者」といいいます) に対し有する材料費・旅費交通費・交際費等の立替経費売掛債権(以下「本債権」といいい ます)を以下のとおり譲渡する契約(以下「本契約 」といいいます)を締結します。丙(甲 の入会する丙のサイトに記載)は、甲と乙の電子取引が円滑に行われるようにオンライン取 引サイトにおいて代行サービスを提供します。
              </Text>
            </Section>
            <Section>
              <Text>
                第 1 条(第3債務者の適格条件)<br />
                1.甲は乙に対して、第3債務者の会社概要、その他乙が要求する第3債務者に関する信用情報等を開示しなければなりません。<br />
                2.期日までに前項の情報に変動があった場合は、甲は乙に報告しなければなりません。
              </Text>
            </Section>
            <Section>
              <Text>
                第2条（⽬的）<br />
                1.本契約は、本債権を、乙が第3債務者からの支払期限前に甲から割引いて買取ることにより、甲の社会活動上で必要な資金を早期に得ることを目的とします。<br />
                2.前項の目的に鑑み、本契約にかかる債権譲渡の第3者対抗要件(以下「対抗要件」といいます)の具備が、甲の社会活動および甲と第3債務者との関係に支障をきたす恐れがあるとの、甲の真摯な申出がある場合、乙が第3債務者の無質力その他不払いのリスク等を考慮した上で、本契約に定められた条件のもと対抗要件具備を留保することができます。<br />
                3.甲は、本契約の締結にあたっては事実のみを申出、乙に本債権額を支払っても社会活動に支障がないなど健全な取引を行います。<br />
                4.丙は、本契約を円滑に行う目的で、甲に本契約を締結する資格があると判断する場合、番号もしくはコードからなる会員番号を、甲に対して割り振り、本契約上は会員番号を用いて甲を特定します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第3条（本債権の適格条件）
              </Text>
              <Text style={sectionBody}>
                甲は、乙に対して本債権に関して次の各項を表明し、かつ、その内容に関して真実であることを保証します。<br />
                なお、甲が真実である旨を保証した次の各項の内容に虚偽が存在した場合、直ちに甲は本契約期限の利益喪失を受け、甲は乙に対し損害の賠償として、乙が甲に支払った代金額の2倍および乙が甲に対し損害賠償の為に裁判等の手段を講じた場合は、訴訟費用または和解手続き費用(弁護士費用、その他費用を含む)を支払います。<br />
                1.本債権が甲と第3債務者との間で締結された請負・委託・雇用契約(以下「原契約」といいます)に基づく業務において発生する立替経費等の売掛債権(雇用契約の場合は賃金を含まない)であること。<br />
                2.原契約が甲と第3債務者との個別合意で、債権譲渡が制限されていないこと。<br />
                3.本債権が本契約締結の時点で、正に存在すること。<br />
                4.本債権が第3者に譲渡されておらず、かつ、第3者の担保権の対象となっていないこと。<br />
                5.本債権が差押、仮差押、仮処分を受けていない、または受ける予定が無いこと。<br />
                6.本債権に対する原契約の解除もしくは解約、取消し、無効、弁済、相殺その他一切の抗弁が存在せず、かつ本債権の履行期日(以下「期日」といいます)においても第3債務者からその全部および一部の支払いを拒絶される見込みがないこと。<br />
                7.本債権に関し、甲が乙に対して開示した情報以外に、甲と第3債務者との間には契約、覚書、合意その他形式を問わず何らの特約もないこと。<br />
                8.その他、本契約書等の書面によって合意した条件、誓約した内容を満たすこと。
              </Text>
            </Section>
            <Section>
              <Text>
                第4条（代⾦と事務⼿数料）<br />
                1.乙は、本債権の譲渡の対価(以下「代金」といいます)を、甲の指定する銀行口座および電子マネー口座に振込む方法により支払います。<br />
                2.乙は、甲の負担となる本契約にかかる、丙の振り込み口座手数料(一律500円)は甲の負担とします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第5条（⼆重譲渡禁⽌等）
              </Text>
              <Text style={sectionBody}>
                甲は、本債権を乙以外の第3者に譲渡(譲渡担保を含む)してはなりません。但し、本契約が解除された場合、もしくは乙が第3者に譲渡することについて書面による承諾を行った場合はこの限りではありません。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第6条（担保責任の排除）
              </Text>
              <Text style={sectionBody}>
                甲は、乙に対して期日における第3債務者の資力を保証するものではなく、甲が本契約締結 時に第3債務者の破産・倒産(以下「事故」といいます)を予期せず、事故により本債権が 債務不履行の場合、甲がその事故の事実を書面によって乙に通知することで本契約を終了することができます。
              </Text>
            </Section>
            <Section>
              <Text>
                第7条（対抗要件の留保）<br />
                1.甲は、乙に代わり、本契約にかかる債権譲渡に関する第3債務者への通知、必要書類の交付、対抗要件の具備に協力しなければなりません。<br />
                2.乙は、第 1 条第 2 項の甲の真摯な申出を受けて、甲に対して、無償で本債権の回収を委託した上で、第3債務者への前項の債権譲渡通知の発送を留保することができます。<br />
                3.甲は、前項の委託により第3債務者から回収した本債権を支払期日に下記口座に振込む方法により乙に引き渡さなければなりません。なお、振込手数料は甲の負担とします。 <br />
                4.期日までの間に甲と連絡がつかなくなるなど甲が本条第2項の回収を怠る又は前項の回収した本債権の乙への引き渡しをしない恐れがあると、乙が判断した場合は、乙は本条第2項で留保した第3債務者への債権譲渡通知の発送をすることができます。<br />
                5.振込人の欄には、甲の登録により当社が割り振り、登録した甲本人のみが利用できる番号またはコードである会員番号・(法人格を除く)第3債務者名を入力するものとします。<br />
                6.会員番号が不明の場合、甲はめ丙の LINE アカウントにて会員番号情報を確認するものとします。<br />
                7.甲が会員番号を誤入力した場合、当該誤入力伝票を上記の LINE アカウントにて提示するものとします。<br />
                8.上記の LINE アカウントにて当該誤入力伝票の提示がなく、お振込み入金を確認できない場合は、お振込み入金がなされなかったものとします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第8条（守秘義務条項）
              </Text>
              <Text style={sectionBody}>
                甲、乙、丙は、それぞれに本契約に関して相手方から取得した一切の情報を秘密とし、正当な理由のない限り第3者に漏洩してはなりません。但し、次の各号に掲げる情報はこの限りではありません。<br />
                1.自己の責に帰すべき事由によらずして公知となった情報。<br />
                2.相手方より受領し又は知りえたときに、すでに自己が保有していた情報。<br />
                3.事前に相手方の書面による同意を得た情報。<br />
                4.相手方から開示された情報によることなく独自に開発・取得した情報。<br />
                5.甲の故意により乙に損害を与えた情報。<br />
                6.丙の会員(利用者・加盟店)資格の停止情報。
              </Text>
            </Section>
            <Section>
              <Text>
                第9条（解除事項）<br />
                1.甲と乙は、相手方が本契約等の各条項に違反したときは、本件契約等を無催告で解除することができます。<br />
                2.甲と乙は、相手方が本契約等の各条項に違反している可能性があると判断したときは、相手方に対して速かに説明(情報の開示を含める)を求めることができるものとし、当該期間内に相手方から合理的な説明がなかった場合は、前項により本契約を解除することができます。<br />
                3.前2項により本件契約を解除した当事者は、相手方に対して損害賠償請求を妨げません。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第10条（コンプライアンス条項）
              </Text>
              <Text style={sectionBody}>
                甲は情を知らない士業を利用し、故意、過失を問わず、乙に損害を与えてはなりません。また、甲と乙は次の各号の事項を表明し、かつ保証します。<br />
                1.暴力団、暴力団員およびこれらに準ずる反社会的勢力(以下「反社」といいます)との間に直接または間接を問わず、何らかの資本ならびに資金上の関係もありません。<br />
                2.反社に対して名目の如何を問わず資金提供を行っていず、今後も行う予定がありません。 <br />
                3.相手方および第3者に対して、暴力的要求行為、法的責任を超えた不当な要求行為、脅迫的言動もしくは暴力的行為、風説の流布、偽計もしくは威力による信用棄損もしくは業務妨害行為、またはこれらに準ずる行為を行ないません。
              </Text>
            </Section>
            <Section>
              <Text>
                第11条（虚偽記載および詐害⾏為等について）<br />
                1.甲は乙に虚偽を申出て、乙に損害を与えてはなりません。<br />
                2.甲は乙に対し本契約締結日から前後2ヶ月の間は係争、破産、債務整理、離職、休業等(以下「会員資格喪失」といいます)の影響で本契約が不履行になるおそれがないことを保証します。<br />
                3.会員資格喪失のおそれが少しでもある場合、甲は乙に対し誠実に真実を申出て、本契約を締結してはなりません。<br />
                4.甲は乙に対して会員資格喪失の可能性を隠し、本件契約を締結して乙から代金を受取りながら、本契約の履行を免れようとしてはなりません。<br />
                5.甲は情を知らない弁護士や司法書士等を利用計画し、故意、過失を問わず、乙に損害を与えてはなりません。<br />
                6.丙は甲が会員資格ないことを誠実に申し出た場合、本契約の締結手続き中であっても中止し、その手続きにかかる事務手数料等は請求しません。
              </Text>
            </Section>
            <Section>
              <Text>
                第12条（丙の免責について）<br />
                1.甲と乙は本契約の成立を目的に丙の電子取引代行サービスを利用するものであり、丙は契約の内容およびその履行等をそれぞれに対し保証するものではありません。<br />
                2.本契約は甲および乙がそれぞれの責任において取引するものであり、本契約が不履行、解除、終了することで損害が発生しても、また甲の故意、過失を問わず乙に損害を与えた場合にも、丙に責任はなく、丙に対し法的、経済的に如何なる請求もしません。<br />
                3.甲は丙に対し、前条によって損害が発生しても、丙に対し法的、経済的に如何なる請求もしません。
              </Text>
            </Section>
            <Section>
              <Text>
                第13条（甲の違反⾏為の開⽰について）<br />
                1.甲が資格喪失を隠して取引した場合、丙は直ちに甲の会員資格を停止し、同情報を丙の加盟店会員と共有することで、2次被害が出ないようにします。<br />
                2.甲は、丙の加盟店会員が通信会社等の場合、第1項の開示によって、甲が将来的にデジタルサービスの提供を受けることができなくなる恐れがあることに同意します。<br />
                3.丙は甲が乙の損害を回復する場合、第1項の開示を停止することができます。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第14条（信義誠実）
              </Text>
              <Text style={sectionBody}>
                甲および乙は、本契約等の各条項に定めない事項又は疑義のある事項については、信義誠実に協議することとします。<br />
                以上、本契約成立の証として、本書を作成して、甲が電子署名の上、各1通を保有し、乙は同1通を丙のメールサーバー上で保存管理します。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第15条（専属的合意管轄）
              </Text>
              <Text style={sectionBody}>
                甲および乙は、本契約等に関する紛争については、訴額に応じて福岡地方裁判所または専属的合意管轄裁判所とします。
              </Text>
            </Section>
            <Section>
              <Text style={sectionHead}>
                第16条（効⼒の発⽣）
              </Text>
              <Text style={sectionBody}>
                本契約の効力は、本契約書の締結後に、乙が甲指定の銀行および電子マネー口座に本契約書記載の代金額から第4条の事務手数料等を差引いた額の振込みを完了した時に発生します。
              </Text>
            </Section>
            <Section>
              <Text>
                ⻄暦 {createdDateJa}
              </Text>
            </Section>
            <Section>
              <Text>（甲）</Text>
              <Text>
                会員番号 {props.userId}<br />
                現住所 〒{props.postalCode} {props.prefectureName} {props.address}<br />
                ⽒名 {props.userFullName}<br />
                携帯番号 {props.phoneNumber}<br />
                緊急連絡先① 名 {props.emergencyContactName} TEL {props.emergencyContactNumber} 続柄 {props.emergencyContactRelation}
              </Text>
            </Section>
            <Section>
              <Text>（⼄）</Text>
              <Text>
                名称  合同会社アトラス
              </Text>
            </Section>
            <Section>
              <Text>
                1 第三者債務者の表⽰<br />
                取引先・勤務先の名称 {props.clientName}<br />
                取引先・勤務先の⽀店・部署等 {props.clientBranch}<br />
                取引先・勤務先の所在地 〒{props.clientPostalCode} {props.clientPrefectureName} {props.clientAddress}<br />
                取引先・勤務先のTEL {props.clientPhoneNumber}
              </Text>
            </Section>
            <Section>
              <Text>
                2 本件債権の表⽰<br />
                (1) 債権の種類 材料費・旅費交通費・交際費等の立替経費売掛債権<br />
                (2) 譲渡する売掛債権の対象 支払期日に受取る売掛債権の発生期間とします<br />
                (3) ⽀払期⽇（期限）：{props.liquidationDateStringJa}<br />
                (4) 譲渡⾦額 上記により特定される売掛債権額のうち ⾦ {props.formattedTransferAmount}円
              </Text>
            </Section>
            <Section>
              <Text>
                3 代⾦の表⽰<br />
                ⾦ {props.formattedReceiveAmount}円<br />
                振込先⾦融機関名→ {props.bankName}<br />
                ⽀店名→ {props.bankBranch}<br />
                ⼝座種⽬→ {props.bankTypeName}<br />
                ⼝座番号→ {props.bankNumber}<br />
                ⼝座名義⼈【カタカナ】→ {props.bankHolderName}
              </Text>
            </Section>
            <Section>
              <Text>
                ※ 確認事項<br />
                甲は本契約が、金銭消費貸借取引ではなく、商品の売買および立替金等の売掛債権譲渡取引であることを理解し、自らの意志で契約するものであり、かつ電子契約法3条に基づき、 甲は自ら入力した(金額および日付を除く)内容に操作ミスによる誤入力があっても内容の確認措置は不要であり、本契約に記載される甲の情報および契約内容に間違いがないことを確認し、同意して電子署名します。
              </Text>
              <Text>
                確認者⽒名 {props.userFullName}
              </Text>
            </Section>
            <Section>
              <Text>
                【同意する: わたしは「プライバシーポリシー」「情報利⽤に関する規約」を確認・同意します。】<br/>
                ☑︎
              </Text>
              <Text>
                【同意する: 申込内容は全て事実であり、各規約に同意して契約を締結します。】<br/>
                ☑︎
              </Text>
            </Section>
            <Text>甲の⽣年⽉⽇ {props.birthStringJa}</Text>
          </Container>
        </Body>
      </Html>
  );
};

const main = {
  backgroundColor: '#ffffff',
  color: '#333',
};

const indentText = {
  textIndent: '1em'
}
const sectionHead = {
  marginBottom: 0
}
const sectionBody = {
  marginTop: 0,
  textIndent: '1em'
}

const container = {
  paddingLeft: '12px',
  paddingRight: '12px',
  margin: '0 auto',
};

const h1 = {
  color: '#333',
  fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '40px 0',
  padding: '0',
};

export default QuickKaitoriNewContractEmailTemplate