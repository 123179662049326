import {Control, FieldPath, FieldValues, useController} from "react-hook-form";
import clsx from "clsx";

type Props<T extends FieldValues> = {
    control: Control<T>
    name: FieldPath<T>
    className?: string
}

export const CheckBoxControl = <T extends FieldValues>({control, name, className} : Props<T>) => {

    const { field} = useController({
        name: name,
        control: control,
    });

    return (
        <input
            className={clsx(
                className
            )}
            type="checkbox"
            ref={field.ref}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            checked={field.value}
        />
    )
}