import {z} from "zod";
import {toHankaku} from "../utils/toHankaku";

const schemaErrorMap: z.ZodErrorMap = (issue, ctx) => {
    switch (issue.code) {
        case z.ZodIssueCode.invalid_literal:
            return { message: '同意が必要です' }
    }
    return { message: ctx.defaultError }
};

export const ReContractFormSchema = z.object({
    userId: z.string({required_error: '入力してください'})
        .max(100, '100文字以内で入力してください')
        .nonempty('入力してください'),
    firstName: z.string({required_error: '入力してください'})
        .max(10, '10文字以内で入力してください')
        .nonempty('入力してください'),
    lastName: z.string({required_error: '入力してください'})
        .max(10,'10文字以内で入力してください')
        .nonempty('入力してください'),
    contractDate: z.date({
        required_error: '日付を入力してください'
    }),
    contractTime: z.object({
        hour: z.string({required_error: '選択してください'}),
        minute: z.string({required_error: '選択してください'})
    }),
    paymentDue: z.date({
        required_error: '日付を入力してください'
    }),
    phoneNumber: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .regex(/^0[-\d]{9,12}$/, '正しい形式の電話番号を入力してください')
            .nonempty('入力してください'),
    ),
    email: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .max(255, '255文字以内で入力してください')
            .email('正しい形式のメールアドレスを入力してください')
            .nonempty('入力してください'),
    ),
    emailConfirmation: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .max(255, '255文字以内で入力してください')
            .email('正しい形式のメールアドレスを入力してください')
            .nonempty('入力してください'),
    ),
    postalCode: z.preprocess(
        (val) => toHankaku(String(val)),
        z.string({required_error: '入力してください'})
            .regex(/^[0-9]{3}-?[0-9]{4}$/, '正しい形式の郵便番号を入力してください')
            .nonempty('入力してください'),
    ),
    prefecture: z.string({required_error: '選択してください'})
        .nonempty('選択してください'),
    address: z.string({required_error: '入力してください'})
        .max(500, '500文字以内で入力してください')
        .nonempty('入力してください'),
    agreements1: z.literal(true, {
        errorMap: schemaErrorMap
    }),
    agreements2: z.literal(true, {
        errorMap: schemaErrorMap
    }),
}).refine(
    (args) => {
        return args.email === args.emailConfirmation
    }, {
        message: '入力されたメールアドレスが異なります',
        path: ['emailConfirmation']
    }
)