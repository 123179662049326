import {useResetRecoilState} from "recoil";
import {NewContractFormInputState} from "../../atoms/NewContractFormInputState";
import React, {useEffect} from "react";
import {ReContractFormInputState} from "../../atoms/ReContractFormInputState";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

const ContractComplete = () => {
    const pathname = useLocation().pathname;
    const resetNewContractState = useResetRecoilState(NewContractFormInputState)
    const resetReContractState = useResetRecoilState(ReContractFormInputState)

    useEffect(() => {
        resetNewContractState()
        resetReContractState()
    }, [resetNewContractState, resetReContractState])

    return (
        <>
            <Helmet>
                {(pathname !== '/complete/mobasoku') && (
                    <title>クイック買取</title>
                )}
                {(pathname === '/complete/mobasoku') && (
                    <title>クイック買取</title>
                )}
            </Helmet>
            <div className="container prose mx-auto max-w-none">
                <div className="px-4 max-w-5xl mx-auto bg-white border min-h-screen lg:px-24 lg:mt-16">
                    <h2>送信完了しました</h2>
                    <p>ご入力いただいたメールアドレスをご確認ください。</p>
                </div>
            </div>
        </>
    )
}

export default ContractComplete
