import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBRwxemmfabsyt2E0jJvbVwyqINvUDdhGE",
    authDomain: "esignature-1525d.firebaseapp.com",
    projectId: "esignature-1525d",
    storageBucket: "esignature-1525d.appspot.com",
    messagingSenderId: "370809913090",
    appId: "1:370809913090:web:e5983277684b9d56c64c1b",
    measurementId: "G-CPSZ5HJZ5E"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
//connectFunctionsEmulator(functions, 'localhost', 5001)

export default functions