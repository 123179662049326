import {useRecoilValue} from "recoil";
import {NewContractFormInputSelector} from "../../atoms/NewContractFormInputState";
import {TableHeader} from "../Elements/Table/TableHeader";
import {TableCell} from "../Elements/Table/TableCell";
import clsx from "clsx";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import functions from "../../libs/firebase";
import LoadingOverlay from "react-loading-overlay-ts"
import QuickKaitoriNewContractEmailTemplate from "../../emails/QuickKaitori/NewContractEmailTemplate"
import { render } from '@react-email/render';
import {NewContractFormInputsSelector} from "../../types/NewContractFormInputs";
import MobasokuNewContractEmailTemplate from "../../emails/Mobasoku/NewContractEmailTemplate";
import {Helmet} from "react-helmet-async";

const NewContractFormConfirm = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const newContractFormInputState = useRecoilValue(NewContractFormInputSelector) as NewContractFormInputsSelector
    const sendEmail = httpsCallable(functions, 'sendmail');
    const [isError, setIsError] = useState(false)

    const onSubmit = () => {
        setLoading(true)
        setIsError(false)
        const to = newContractFormInputState.email
        if (newContractFormInputState.contract === 'quick-kaitori') {
            const html = render(<QuickKaitoriNewContractEmailTemplate {...newContractFormInputState} />);
            const text = render(<QuickKaitoriNewContractEmailTemplate {...newContractFormInputState} />, {
                plainText: true
            });
            const subject = "【クイック買取契約書】" + newContractFormInputState.userFullName
            const from = "クイック買取"
            send(html, text, to, subject, from)
        }
        if (newContractFormInputState.contract === 'mobasoku') {
            const html = render(<MobasokuNewContractEmailTemplate {...newContractFormInputState} />);
            const text = render(<MobasokuNewContractEmailTemplate {...newContractFormInputState} />, {
                plainText: true
            });
            const subject = "【クイック買取契約書】" + newContractFormInputState.userFullName
            const from = 'クイック買取'
            send(html, text, to, subject, from)
        }

        function send(html: string, text: string, to: string, subject: string, from: string) {
            sendEmail({html, text, to, subject, from})
                .then(() => {
                    setLoading(false)
                    navigate('/complete', {replace: true})
                })
                .catch((_) => {
                    setLoading(false)
                    setIsError(true)
                })
        }

    }

    return (
        <>
            <Helmet>
                {(newContractFormInputState.contract === 'quick-kaitori') && (
                    <title>クイック買取</title>
                )}
                {(newContractFormInputState.contract === 'mobasoku') && (
                    <title>クイック買取</title>
                )}
            </Helmet>
            <LoadingOverlay spinner text='データを送信しています...' active={loading}>
                <div className="container prose mx-auto max-w-none">
                    <div className="px-4 max-w-5xl mx-auto bg-white border lg:px-24 lg:mt-16">
                        <section>
                            <h2>新規契約フォーム</h2>
                            <p>迷惑メール設定の場合はinfo@quickkaitori.netからのメールを受信できるように設定変更をお願いいたします。</p>
                            <p>内容をご確認いただき、お間違いがなければ一番下のボタンを押してください。</p>
                        </section>
                        {isError && (
                            <section>
                                <div className="text-red-500 bg-red-50 border-2  px-4 py-1 border-red-500">
                                    エラーが発生しました。<br />
                                    ご迷惑をお掛けいたしますが、現在対応中ですのでしばらくお時間を置いて操作を再開してください。
                                </div>
                            </section>
                        )}
                        <section>
                            <h3 className="border-b-2 border-black mb-6">１．ご本人様（お名前など）について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="会員番号"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.userId}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="お名前(全角)"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.lastName} {newContractFormInputState.firstName}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="生年月日"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.birth.year}年
                                    {newContractFormInputState.birth.month}月
                                    {newContractFormInputState.birth.day}日
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="携帯番号"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.phoneNumber}
                                </span>
                                    </TableCell>
                                </tr>

                                <tr>
                                    <TableHeader label="メールアドレス"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.email}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="ご住所"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.postalCode}<br />
                                    {newContractFormInputState.prefectureName}<br />
                                    {newContractFormInputState.address}
                                </span>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">２．緊急連絡先について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="緊急連絡先 お名前(親族)"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.emergencyContactName}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="緊急連絡先(携帯番号)"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.emergencyContactNumber}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="続柄"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.emergencyContactRelation}
                                </span>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">３．取引先・勤務先について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="取り引き先、勤務先名"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.clientName}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="取引先・勤務先の支店・部署等"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.clientBranch}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="取引先・勤務先住所"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.clientPostalCode}<br />
                                    {newContractFormInputState.clientPrefectureName}<br />
                                    {newContractFormInputState.clientAddress}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="取引先・勤務先電話番号"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.clientPhoneNumber}
                                </span>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">４．立替経費売掛債権について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="立替精算期日"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.liquidationDateStringJa}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="当社だけに譲渡できる売掛債権"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.formattedTransferAmount}円
                                </span>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">５．受取る代金額</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="受取る代金額"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.formattedReceiveAmount}円
                                </span>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">６．振込口座について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="金融機関名"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.bankName}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="支店名"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.bankBranch}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="口座種目"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.bankTypeName}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="口座番号"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.bankNumber}
                                </span>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="口座名義人【カタカナ】"  />
                                    <TableCell>
                                <span>
                                    {newContractFormInputState.bankHolderName}
                                </span>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <div className="text-center">
                                <div className="mb-12">
                                    <button
                                        onClick={onSubmit}
                                        className={clsx(
                                            'bg-[#9b8d77] text-white',
                                            'border-b-2 border-b-black',
                                            'rounded-md',
                                            'w-64 h-12',
                                            'cursor-pointer',
                                            'hover:opacity-70',
                                            'disabled:bg-gray-400 disabled:cursor-not-allowed'
                                        )}
                                        type="button"
                                    >上記内容にて契約書に署名</button>
                                </div>
                                <div className="mb-12">
                                    <Link to="/contract" className="text-red-600 font-bold">入力をやり直す</Link>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default NewContractFormConfirm
