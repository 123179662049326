import {Control, FieldPath, FieldValues, useController} from "react-hook-form";
import {InputHTMLAttributes} from "react";
import clsx from "clsx";

type Props<T extends FieldValues> = {
    control: Control<T>
    name: FieldPath<T>
} & InputHTMLAttributes<HTMLInputElement>

const variants = {
    error: {
        className: "bg-red-100 border-red-500 focus:border-red-500"
    }
}

export const InputFieldControl = <T extends FieldValues>({control, name, className, autoFocus, placeholder, type , min} : Props<T>) => {

    const { field, fieldState: {isDirty, invalid}} = useController({
        name: name,
        control: control,
    });

    return (
        <input
            className={clsx(
                "border-gray-300 w-full text-sm",
                (invalid) ? variants.error.className : '',
                (isDirty || field.value !== '') ? "text-gray-900" : "text-gray-400",
                className
            )}
            autoFocus={autoFocus}
            placeholder={placeholder}
            type={type}
            value={field.value}
            ref={field.ref}
            onChange={field.onChange}
            onBlur={field.onBlur}
            min={min}
        />
    )
}