import {TableHeader} from "../Elements/Table/TableHeader";
import {TableCell} from "../Elements/Table/TableCell";
import {InputFieldControl} from "../Forms/InputFieldControl";
import {ValidationErrorText} from "../Forms/ValidationErrorText";
import {YearSelectFieldControl} from "../Forms/YearSelectFieldControl";
import clsx from "clsx";
import {MonthSelectFieldControl} from "../Forms/MonthSelectFieldControl";
import {DaySelectFieldControl} from "../Forms/DaySelectFieldControl";
import {PrefectureSelectFieldControl} from "../Forms/PrefectureSelectFieldControl";
import {SelectFieldControl} from "../Forms/SelectFieldControl";
import {RequiredBadge} from "../Elements/RequiredBadge";
import {CheckBoxControl} from "../Forms/CheckBoxControl";
import React from "react";
import {useNewContractForm} from "../../hooks/useNewContractForm";
import {NewContractFormInputs} from "../../types/NewContractFormInputs";
import {useSetRecoilState} from "recoil";
import {NewContractFormInputState} from "../../atoms/NewContractFormInputState";
import {useNavigate} from "react-router-dom";
import {DatePickerFieldControl} from "../Forms/DatePickerFieldControl";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet-async";

const NewContractForm = () => {

    const navigate = useNavigate()
    const setNewContractFormInputState = useSetRecoilState(NewContractFormInputState)
    const pathname = useLocation().pathname;

    const {
        control,
        handleSubmit,
        errors,
    } = useNewContractForm()

    const onHandleSubmit = (data: NewContractFormInputs) => {
        if (pathname === '/contract/mobasoku' || pathname === '/mobasoku') {
            data.contract = 'mobasoku'
        } else {
            data.contract = 'quick-kaitori'
        }
        setNewContractFormInputState(data)
        navigate('/confirm')
    }

    return (
        <>
            <Helmet>
                {(pathname !== '/contract/mobasoku' && pathname !== '/mobasoku') && (
                    <title>クイック買取</title>
                )}
                {(pathname === '/contract/mobasoku' || pathname === '/mobasoku') && (
                    <title>クイック買取</title>
                )}
            </Helmet>
            <div className="container prose mx-auto max-w-none">
                <div className="px-4 max-w-5xl mx-auto bg-white border lg:px-24 lg:mt-16">
                    <section>
                        <h2>新規契約フォーム</h2>
                        <p>迷惑メール設定の場合はinfo@quickkaitori.netからのメールを受信できるように設定変更してからご記入ください。</p>
                        <p>こちらのフォームに入力いただいた内容を元に、ご指定のメールアドレスへ電子契約書メールが送付されます。送信後は受信メールを必ずご確認ください。</p>

                        {Object.keys(errors).length > 0 && (
                            <div className="border-2 border-red-500 bg-red-100 text-red-500 py-2 px-3">入力項目にエラーがあります。<br />入力内容をご確認ください。</div>
                        )}

                    </section>
                    <form onSubmit={handleSubmit(onHandleSubmit)}>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">１．ご本人様（お名前など）について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="会員番号" required />
                                    <TableCell>
                                        <span className="text-sm inline-block">*LINEにてお申込みの際に弊社より発行した会員番号をご記入ください。</span>
                                        <InputFieldControl type="text" control={control} name="userId" />
                                        {errors.userId?.message && (<ValidationErrorText>{errors.userId.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="お名前(全角)" required />
                                    <TableCell>
                                        <div className="flex gap-1">
                                            <div>
                                                <span className="text-xs">姓</span>
                                                <InputFieldControl type="text" control={control} name="lastName" placeholder="佐藤" />
                                                {errors.lastName?.message && (<ValidationErrorText>{errors.lastName.message}</ValidationErrorText>)}
                                            </div>
                                            <div>
                                                <span className="text-xs">名</span>
                                                <InputFieldControl type="text" control={control} name="firstName" placeholder="一郎" />
                                                {errors.firstName?.message && (<ValidationErrorText>{errors.firstName.message}</ValidationErrorText>)}
                                            </div>
                                        </div>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="生年月日" required />
                                    <TableCell>
                                        <div className="flex gap-2">
                                            <div>
                                                <span className="text-xs">年</span>
                                                <YearSelectFieldControl className={clsx(
                                                    (errors.birth?.message) ? "bg-red-100 border-red-500 focus:border-red-500" : ""
                                                )} control={control} name="birth.year" />
                                            </div>
                                            <div>
                                                <span className="text-xs">月</span>
                                                <MonthSelectFieldControl className={clsx(
                                                    (errors.birth?.message) ? "bg-red-100 border-red-500 focus:border-red-500" : ""
                                                )} control={control} name="birth.month" />
                                            </div>
                                            <div>
                                                <span className="text-xs">日</span>
                                                <DaySelectFieldControl className={clsx(
                                                    (errors.birth?.message) ? "bg-red-100 border-red-500 focus:border-red-500" : ""
                                                )} control={control} name="birth.day" />
                                            </div>
                                        </div>
                                        {errors.birth?.year?.message && (<ValidationErrorText className="block">{errors.birth.year.message}</ValidationErrorText>)}
                                        {errors.birth?.month?.message && (<ValidationErrorText className="block">{errors.birth.month.message}</ValidationErrorText>)}
                                        {errors.birth?.day?.message && (<ValidationErrorText className="block">{errors.birth.day.message}</ValidationErrorText>)}
                                        {errors.birth?.message && (<ValidationErrorText className="block">{errors.birth.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="携帯番号" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="phoneNumber" placeholder="例) 09056781234" />
                                        {errors.phoneNumber?.message && (<ValidationErrorText className="block">{errors.phoneNumber.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>

                                <tr>
                                    <TableHeader label="メールアドレス" required />
                                    <TableCell>
                                        <div className="mb-3">
                                            <span className="text-sm inline-block">*電子契約書を受取る事ができるメールアドレスをご記入ください</span>
                                            <InputFieldControl type="email" control={control} name="email" placeholder="example@example.com" />
                                            {errors.email?.message && (<ValidationErrorText>{errors.email.message}</ValidationErrorText>)}
                                        </div>
                                        <div>
                                            <span className="text-sm inline-block">確認のためもう一度ご入力してください</span>
                                            <InputFieldControl type="email" control={control} name="emailConfirmation" placeholder="example@example.com" />
                                            {errors.emailConfirmation?.message && (<ValidationErrorText>{errors.emailConfirmation.message}</ValidationErrorText>)}
                                        </div>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="ご住所" required />
                                    <TableCell>
                                        <div className="mb-1">
                                            <span className="text-xs">郵便番号</span>
                                            <InputFieldControl type="text" control={control} name="postalCode" placeholder="1234567" />
                                            {errors.postalCode?.message && (<ValidationErrorText>{errors.postalCode.message}</ValidationErrorText>)}
                                        </div>
                                        <div className="mb-1">
                                            <span className="text-xs">都道府県</span>
                                            <PrefectureSelectFieldControl control={control} name="prefecture" />
                                            {errors.prefecture?.message && (<ValidationErrorText>{errors.prefecture.message}</ValidationErrorText>)}
                                        </div>
                                        <div>
                                            <span className="text-xs">ご住所</span>
                                            <InputFieldControl type="text" control={control} name="address" placeholder="○○市○○1-2-3 ○○マンション 101号室" />
                                            {errors.address?.message && (<ValidationErrorText>{errors.address.message}</ValidationErrorText>)}
                                        </div>
                                        <div className="text-sm">
                                            <p>*ご住所は、本人確認書類（運転免許証など）と同じ内容をご入力してください。</p>
                                            <p>*住所の丁目や番地、号等は、－（ハイフン）でご入力してください。</p>
                                        </div>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">２．緊急連絡先について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="緊急連絡先 お名前(親族)" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="emergencyContactName" placeholder="例) 佐藤 二郎" />
                                        {errors.emergencyContactName?.message && (<ValidationErrorText>{errors.emergencyContactName.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="緊急連絡先(携帯番号)" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="emergencyContactNumber" placeholder="例) 09056781234" />
                                        {errors.emergencyContactNumber?.message && (<ValidationErrorText>{errors.emergencyContactNumber.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="続柄" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="emergencyContactRelation" placeholder="例) 父、母、兄弟、姉妹、祖母、祖父" />
                                        {errors.emergencyContactRelation?.message && (<ValidationErrorText>{errors.emergencyContactRelation.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">３．取引先・勤務先について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="取り引き先、勤務先名" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="clientName" placeholder="例) 株式会社○○、○○株式会社" />
                                        {errors.clientName?.message && (<ValidationErrorText>{errors.clientName.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="取引先・勤務先の支店・部署等" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="clientBranch" placeholder="例)本社、○○支店、○○営業所" />
                                        <span className="text-xs">※部署が無い場合は「本社」と入力ください。</span>
                                        {errors.clientBranch?.message && (<ValidationErrorText className="block">{errors.clientBranch.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="取引先・勤務先住所" required />
                                    <TableCell>
                                        <div className="mb-1">
                                            <span className="text-xs">郵便番号</span>
                                            <InputFieldControl type="text" control={control} name="clientPostalCode" placeholder="1234567" />
                                            {errors.clientPostalCode?.message && (<ValidationErrorText>{errors.clientPostalCode.message}</ValidationErrorText>)}
                                        </div>
                                        <div className="mb-1">
                                            <span className="text-xs">都道府県</span>
                                            <PrefectureSelectFieldControl control={control} name="clientPrefecture" />
                                            {errors.clientPrefecture?.message && (<ValidationErrorText>{errors.clientPrefecture.message}</ValidationErrorText>)}
                                        </div>
                                        <div>
                                            <span className="text-xs">ご住所</span>
                                            <InputFieldControl type="text" control={control} name="clientAddress" placeholder="○○市○○1-2-3 ○○マンション 101号室" />
                                            {errors.clientAddress?.message && (<ValidationErrorText>{errors.clientAddress.message}</ValidationErrorText>)}
                                        </div>
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="取引先・勤務先電話番号" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="clientPhoneNumber" placeholder="例) 0056781234" />
                                        {errors.clientPhoneNumber?.message && (<ValidationErrorText>{errors.clientPhoneNumber.message}</ValidationErrorText>)}
                                        <div>
                                            <span className="text-xs inline-block mt-2">*固定番号でお願い致します。(携帯番号は確認が必要になる場合がございます)</span>
                                        </div>
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">４．立替経費売掛債権について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="立替精算期日" required />
                                    <TableCell>
                                        <DatePickerFieldControl control={control} name="liquidationDate" />
                                        {errors.liquidationDate?.message && (<ValidationErrorText className="block">{errors.liquidationDate.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="当社だけに譲渡できる売掛債権" required />
                                    <TableCell>
                                        <div className="flex items-center gap-x-2">
                                            <InputFieldControl min={0} type="text" control={control} name="transferAmount" className="w-48" />
                                            <span>円</span>
                                        </div>
                                        {errors.transferAmount?.message && (<ValidationErrorText className="block">{errors.transferAmount.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">５．受取る代金額</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="受取る代金額" required />
                                    <TableCell>
                                        <div className="flex items-center gap-x-2">
                                            <InputFieldControl min={0} type="text" control={control} name="receiveAmount" className="w-48" />
                                            <span>円</span>
                                        </div>
                                        {errors.receiveAmount?.message && (<ValidationErrorText className="block">{errors.receiveAmount.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <h3 className="border-b-2 border-black mb-6">６．振込口座について</h3>
                            <table className="border">
                                <tbody>
                                <tr>
                                    <TableHeader label="金融機関名" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="bankName" placeholder="例) ○○銀行" />
                                        {errors.bankName?.message && (<ValidationErrorText className="block">{errors.bankName.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="支店名" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="bankBranch" placeholder="例) ○○支店" />
                                        <span className="text-sm inline-block">*ゆうちょ銀行の場合は128支店と3桁の数字でお願い致します</span>
                                        {errors.bankBranch?.message && (<ValidationErrorText className="block">{errors.bankBranch.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="口座種目" required />
                                    <TableCell>
                                        <SelectFieldControl control={control} name="bankType">
                                            <option value="">タップして選択</option>
                                            <option value="1">普通</option>
                                            <option value="2">当座</option>
                                        </SelectFieldControl>
                                        {errors.bankType?.message && (<ValidationErrorText className="block">{errors.bankType.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="口座番号" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="bankNumber" placeholder="例) 01234567" />
                                        {errors.bankNumber?.message && (<ValidationErrorText className="block">{errors.bankNumber.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                <tr>
                                    <TableHeader label="口座名義人【カタカナ】" required />
                                    <TableCell>
                                        <InputFieldControl type="text" control={control} name="bankHolderName" placeholder="例) サトウイチロウ" />
                                        <span className="text-sm inline-block">*必ずカタカナご記入でご本人様名義になります</span>
                                        {errors.bankHolderName?.message && (<ValidationErrorText className="block">{errors.bankHolderName.message}</ValidationErrorText>)}
                                    </TableCell>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <div className="px-3">
                                <RequiredBadge />
                                <label className="align-middle inline-block ml-2 mt-1 cursor-pointer">
                                    <CheckBoxControl control={control} name="agreements" className="mr-2 -mt-1" />
                                    <span>わたしは「プライバシーポリシー」「情報利用に関する規約」を確認・同意します</span>
                                </label>
                            </div>
                            {errors.agreements?.message && (<ValidationErrorText className="block">{errors.agreements.message}</ValidationErrorText>)}
                        </section>
                        <section>
                            <h3 className="mb-6">お申し込み前の<br />コンプライアンス確認項目</h3>
                            <h3 className="border-b-2 border-black mb-6">(必ずお読みください)</h3>
                            <p>お申込内容(提出書類やフォーム入力事項)は、本契約に大きく影響致しますので、お申込内容が事実とは異なる虚偽の場合、法的処置をとる場合があります。</p>
                            <p>未だ、ご契約締結前ですので、もし間違えている部分がありましたら、上記フォームの内容をご訂正下さい。</p>
                            <p className="mb20">その上で以下の回答をお願い致します。</p>
                            <div className="px-3">
                                <RequiredBadge />
                                <label className="align-middle inline-block ml-2 mt-1 cursor-pointer">
                                    <CheckBoxControl control={control} name="compliance" className="mr-2 -mt-1" />
                                    {(pathname !== '/contract/mobasoku' && pathname !== '/mobasoku') && (
                                        <span>申込内容は全て事実であり、各規約に同意して契約を締結します。</span>
                                    )}
                                    {(pathname === '/contract/mobasoku' || pathname === '/mobasoku') && (
                                        <span>
                                            利用者は、本規約が商品の売買および取引についての定めであることを理解し、同意し、利用にあたっては異議を申し立てず、自らの意志で入会するものであり、申込内容に間違いがないことを確認し、同意して電子署名します。
                                        </span>
                                    )}
                                </label>
                            </div>
                            {errors.compliance?.message && (<ValidationErrorText className="block">{errors.compliance.message}</ValidationErrorText>)}
                        </section>
                        <section>
                            <p className="text-center">
                                <input
                                    className={clsx(
                                        'bg-[#9b8d77] text-white',
                                        'border-b-2 border-b-black',
                                        'rounded-md',
                                        'w-64 h-12',
                                        'cursor-pointer',
                                        'hover:opacity-70',
                                        'disabled:bg-gray-400 disabled:cursor-not-allowed'
                                    )}
                                    type="submit"
                                    value="確認画面へ" />
                            </p>
                        </section>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewContractForm
